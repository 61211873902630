import React, { ReactElement } from 'react';
import { Modal, Button } from 'react-bootstrap';
import DOMPurify from 'dompurify';

import { useTranslation } from 'react-i18next';
import './WorkplaceAlertModal.scss';
import { Workplace } from '../model/Workplace';
import { MemberTypeCode } from '../model/ReservationConstants';

export interface WorkplaceAlertModalProps {
  workplace: Workplace;
  alertType: MemberTypeCode;
  show: boolean;
  onClose: Function;
}

export const WorkplaceAlertModal: React.FC<WorkplaceAlertModalProps> = (
  props: WorkplaceAlertModalProps
): ReactElement => {
  const { t } = useTranslation();
  const onClose = () => {
    props.onClose();
  };
  const workplace: Workplace = props.workplace;
  const isNonMember: boolean = props.alertType === MemberTypeCode.NO_MEM;
  const impossibleUrl: string = isNonMember
    ? workplace.nonmemberRegisterImpossibleUrl
    : workplace.memberRegisterImpossibleUrl;
  const message: string | undefined = isNonMember
    ? workplace.nonmemberRegisterImpossibleMessage
    : workplace.memberRegisterImpossibleMessage;
  const handlePageLocation = () => {
    impossibleUrl ? window.location.assign(impossibleUrl) : onClose();
  };
  return (
    <Modal
      id="workplaceAlertModal"
      data-testid="workplaceAlertModal"
      show={props.show}
      onHide={onClose}
      className="workplaceAlertModal"
      enforceFocus={false}
      centered
    >
      <Modal.Header>
        <p>
          <b
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(
                t('reservation.label.ReservationNonEmpUrlModal.headerMsg', {
                  companyName: workplace.groupCompanyName,
                  workplace: workplace.workplace,
                })
              ),
            }}
          ></b>
          <span>{t('reservation.label.ReservationNonEmpUrlModal.headerSubMsg')}</span>
        </p>
      </Modal.Header>
      <div id="nonEmpUrlModalContentContainer">
        <span>{message}</span>
        <br />
        <a onClick={handlePageLocation}>
          <span id="out-link">{impossibleUrl}</span>
        </a>
      </div>
      <div id="nonEmpUrlModalButtonContainer">
        <Button
          id="cancelButton"
          name="cancelButton"
          data-testid="cancelButton"
          className="cancel-button"
          onClick={onClose}
          variant="dark"
        >
          {t('reservation.label.ConfirmModal.button.cancel')}
        </Button>
        <Button
          variant="primary"
          id="okButton"
          name="okButton"
          data-testid="okButton"
          className="ok-button"
          onClick={handlePageLocation}
        >
          <i id="firstRightArrow" data-testid="firstRightArrow" className="material-icons check-icon">
            check
          </i>
          {t('reservation.label.ConfirmModal.button.confirm')}
        </Button>
      </div>
    </Modal>
  );
};

export default WorkplaceAlertModal;
