import React, { useState, useEffect, useMemo } from 'react';
import './SystemNoticeModal.scss';
import { Modal, Accordion, Card, Button, Row, Col, Form, Dropdown, DropdownButton } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { RequestGetNoticeList, NoticeList, NoticeModifyMode } from '../model/Workplace';
import WorkplaceService from '../services/WorkplaceService';
import moment from 'moment';
import DOMPurify from 'dompurify';
import CommonSpinner from '../../../layouts/Spinner';
import { DateFormat } from '../model/ReservationConstants';
import { reservationDetailUtil } from '../utils/ReservationDetailUtil';
import { User } from '../model/User';
import DropdownItem from 'react-bootstrap/DropdownItem';

export interface SystemNoticeModalI {
  onHide: () => void;
  onModifyNotice?: (target: NoticeList | null, mode: NoticeModifyMode) => void;
  sessionUser: User | null;
}

export const SystemNoticeModal: React.FC<SystemNoticeModalI> = (props: SystemNoticeModalI) => {
  const { t } = useTranslation();
  const maxDisplayItemCount = 5;
  const [totalPageCount, setTotalPageCount] = useState(0);
  const [noticeList, setNoticeList] = useState<Array<NoticeList>>([]);
  const [currentPageIndex, setCurrentPageIndex] = useState(0);
  const [currentActiveItemIndex, setCurrentActiveItemIndex] = useState('0');
  const [keyword, setKeyword] = useState<string>('');
  const workplaceService = new WorkplaceService();
  const path = process.env.REACT_APP_S3_FILE_DOWNLOAD_URL || '';
  const filesizeFormatter = (bytes: number): string => {
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    if (bytes === 0) return '0 Byte';
    const i = Math.floor(Math.log(bytes) / Math.log(1024));
    return '(' + Math.round((bytes / Math.pow(1024, i)) * 100) / 100 + sizes[i] + ')';
  };
  const getRegisterDate = registerDate => {
    return moment(registerDate).format(DateFormat.DATE_DISP);
  };
  const [isLoading, setLoading] = useState<boolean>(false);
  const [searchType, setSearchType] = useState<string>('title');
  const [searchItemIndex, setSearchItemIndex] = useState<number>(0);
  const searchItem: string[] = [
    t('reservation.label.SystemNoticeModal.searchTitle'),
    t('reservation.label.SystemNoticeModal.searchContent'),
  ];

  const handleModifyButtonClick = (e: any, modifyType: NoticeModifyMode, item: NoticeList | null) => {
    e.stopPropagation();
    e.preventDefault();

    if (props.onModifyNotice) {
      props.onModifyNotice(item, modifyType);
    }
  };

  const getNoticeList = async () => {
    setLoading(true);
    const request: RequestGetNoticeList = {
      languageCode: localStorage.getItem('lang') ? (localStorage.getItem('lang') as string) : 'ko',
      startIndex: currentPageIndex * maxDisplayItemCount,
      pageSize: maxDisplayItemCount,
    };
    if (props.sessionUser && props.sessionUser.isSystemAdmin) {
      request.requestedId = props.sessionUser.id;
    }
    if (keyword && keyword != null) {
      request.keyword = keyword;
      request.searchType = searchType;
    }
    return await workplaceService.getNoticeList(request);
  };

  /* eslint-disable */
  const getNoticeListByKeyword = async () => {
    getNoticeList()
      .then(response => {
        if (response) {
          setNoticeList(response.noticeList);
          setTotalPageCount(response.totalPageCount);
          setCurrentActiveItemIndex('0');
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }

  const onSelectItem = (index: string) => {
    setSearchItemIndex(Number(index))
    Number(index) === 0
    ? setSearchType('title')
    : setSearchType('content')
  }

  useEffect(() => {
    getNoticeList()
      .then(response => {
        if (response) {
          setNoticeList(response.noticeList);
          setTotalPageCount(response.totalPageCount);
          setCurrentActiveItemIndex('0');
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, [currentPageIndex]);
  /* eslint-enable */

  return (
    <Modal
      show={true}
      onHide={props.onHide}
      centered={true}
      className="system-notice-modal"
      data-testid="system-notice-modal"
      id="systemNoticeModal"
    >
      <Modal.Header closeButton>
        <div className="title" data-testid="notice-modal-header">
          {t('reservation.label.SystemNoticeModal.title')}
        </div>
      </Modal.Header>
      <Modal.Body>
        <div className="notice-filter-area">
          <Row>
            <DropdownButton id="select-search" className="select-search" title={searchItem[searchItemIndex]}>
              {searchItem.map((key, index) => {
                return (
                  <DropdownItem
                    id="select-search-item"
                    className="select-search-item"
                    key={index}
                    eventKey={String(index)}
                    onSelect={onSelectItem}
                  >
                    {searchItem[index]}
                  </DropdownItem>
                );
              })}
            </DropdownButton>
            <Col>
              <Form.Control
                id="noticeInput"
                name="noticeInput"
                className="notice-input-control"
                type="text"
                value={keyword}
                onChange={e => setKeyword(e.target.value)}
              />
            </Col>
            <Button
              id="noticeButton"
              name="noticeButton"
              className="notice-search-button"
              type="submit"
              onClick={() => getNoticeListByKeyword()}
            >
              <i id="searchIcon" data-testid="searchIcon" className="material-icons">
                search
              </i>
              <span>검색</span>
            </Button>
          </Row>
        </div>
        {!isLoading && noticeList !== undefined && noticeList.length && noticeList.length > 0 ? (
          <div>
            <Accordion activeKey={currentActiveItemIndex}>
              {noticeList.map((item, i) => {
                return (
                  <Card key={i}>
                    <Accordion.Toggle
                      as={Card.Header}
                      eventKey={i + ''}
                      onClick={() => {
                        if (currentActiveItemIndex === i + '') {
                          setCurrentActiveItemIndex('');
                        } else {
                          setCurrentActiveItemIndex(i + '');
                        }
                      }}
                    >
                      <Row>
                        <Col className="list">
                          <span className={item.importanceYn === 'Y' ? 'importance' : ''}>{item.noticeTitle}</span>
                          <span className="registerDate">{getRegisterDate(item.registerDate)}</span>
                          {props.onModifyNotice && props.sessionUser && props.sessionUser.isSystemAdmin && (
                            <span className="button-for-admin">
                              <i
                                className="material-icons edit"
                                onClick={e => {
                                  handleModifyButtonClick(e, NoticeModifyMode.EDIT, item);
                                }}
                              >
                                edit
                              </i>

                              <i
                                className={'material-icons star' + (item.importanceYn === 'Y' ? ' filled' : ' gray')}
                                onClick={e => {
                                  handleModifyButtonClick(e, NoticeModifyMode.IMPORTANCE, item);
                                }}
                              >
                                {item.importanceYn === 'Y' ? 'star' : 'star_outline'}
                              </i>
                              <i
                                className={'material-icons eye' + (item.useYn === 'Y' ? ' filled' : ' gray')}
                                onClick={e => {
                                  handleModifyButtonClick(e, NoticeModifyMode.VISIBLE, item);
                                }}
                              >
                                {item.useYn === 'Y' ? 'visibility' : 'visibility_off'}
                              </i>
                              <i
                                className={'material-icons pin' + (item.popupYn === 'Y' ? ' filled' : ' gray')}
                                onClick={e => {
                                  handleModifyButtonClick(e, NoticeModifyMode.POPUP, item);
                                }}
                              >
                                open_in_new
                              </i>
                            </span>
                          )}
                          <i className="material-icons arrow">
                            {currentActiveItemIndex === i + '' ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}
                          </i>
                        </Col>
                      </Row>
                    </Accordion.Toggle>
                    <div className="line" />
                    <Accordion.Collapse eventKey={i + ''}>
                      <div>
                        <Card.Body>
                          {item.fileOriginalName !== null ? (
                            <Row>
                              <Col md="auto">
                                <i className="material-icons">get_app</i>
                              </Col>
                              <Col>
                                <a
                                  href={path + item.filePath + '/' + item.fileOriginalName}
                                  target="_blank"
                                  download={item.fileName}
                                >
                                  {t('reservation.label.SystemNoticeModal.attatchedFile') +
                                    item.fileName +
                                    '.' +
                                    item.fileExtension +
                                    ' ' +
                                    filesizeFormatter(item.fileSize)}
                                </a>
                              </Col>
                            </Row>
                          ) : (
                            ''
                          )}
                          <Row>
                            <span
                              className="center"
                              dangerouslySetInnerHTML={{
                                __html: DOMPurify.sanitize(item.noticeContent.replace(/(\n|\r\n)/g, '<br>')),
                              }}
                            ></span>
                          </Row>
                        </Card.Body>
                        <div className="line" />
                      </div>
                    </Accordion.Collapse>
                  </Card>
                );
              })}
            </Accordion>
            <p>
              <Button
                className="btn-arrow"
                disabled={currentPageIndex === 0}
                onClick={() => {
                  setCurrentPageIndex(Math.max(currentPageIndex - 1, 0));
                }}
              >
                <i className="material-icons">keyboard_arrow_left</i>
              </Button>
              <Button
                className="btn-arrow"
                disabled={currentPageIndex === totalPageCount - 1}
                onClick={() => {
                  setCurrentPageIndex(Math.min(currentPageIndex + 1, totalPageCount - 1));
                }}
              >
                <i className="material-icons">keyboard_arrow_right</i>
              </Button>
            </p>
          </div>
        ) : (
          !isLoading && <div className="no-notice">{t('reservation.label.SystemNoticeModal.noContent')}</div>
        )}
        {isLoading && <CommonSpinner />}
        <Button className="btn-ok" onClick={props.onHide}>
          {t('reservation.label.SystemNoticeModal.confirm')}
        </Button>
        {props.onModifyNotice && props.sessionUser && props.sessionUser.isSystemAdmin && (
          <Button
            className="btn-create"
            variant="dark"
            onClick={e => {
              handleModifyButtonClick(e, NoticeModifyMode.CREATE, null);
            }}
          >
            신규생성
          </Button>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default SystemNoticeModal;
