import React, { useState, useEffect } from 'react';
import DOMPurify from 'dompurify';
import './TermsAcceptionModal.scss';
import { Modal, Container } from 'react-bootstrap';

import { UserTypeParamEnumForTermViewAPICall } from '../../model/MemberRegistrationConstants';
import TermsInfo from '../../model/TermsInfo';
import TermService from '../../services/TermService';
import { useTranslation } from 'react-i18next';
import { AuthContext } from '../../../../App';

export interface TermsAcceptionModalI {
  term: TermsInfo | undefined;
  termService?: TermService;
  termsTypeCode?: string;
  memberTypeCode?: string;
  onHide: () => void;
}

export const TermsAcceptionModal: React.FC<TermsAcceptionModalI> = (props: TermsAcceptionModalI) => {
  const { t } = useTranslation();

  const [terms, setTerms] = useState<TermsInfo | undefined>();
  const [shows, setShows] = useState<boolean>(false);

  useEffect(() => {
    if (props.term) {
      setTerms(props.term);
      setShows(true);
    } else {
      if (props.memberTypeCode && props.memberTypeCode !== UserTypeParamEnumForTermViewAPICall.WRONG_PARAM) {
        const fetchTermData = async (memeberType: string) => {
          const termService = props.termService ? props.termService : new TermService();
          return await termService.requestTermInfo(memeberType);
        };
        fetchTermData(props.memberTypeCode).then(res => {
          const response = JSON.parse(JSON.stringify(res));

          if (response.successOrNot === 'N') {
            alert(t('member.message.backEnd.UE'));
          } else {
            if (response.data !== undefined && response.data.length > 0) {
              response.data.map((dat: TermsInfo) => {
                if (props.termsTypeCode && dat.termsTypeCode === props.termsTypeCode) {
                  setTerms(dat);
                  setShows(true);
                }
              });
            }
          }
        });
      }
    }
  }, [props.memberTypeCode, props.term, props.termService, props.termsTypeCode, t]);

  return (
    <Modal
      show={shows}
      onHide={props.onHide}
      centered={true}
      className="terms-acception-modal"
      data-testid="terms-acception-modal-test"
      id="TermsAcceptionModal"
    >
      <Modal.Header closeButton>
        <div className="title bold" data-testid="terms-acception-title">
          {terms ? (terms.termsTitle ? terms.termsTitle : '') : ''}
        </div>
        <div className="modal__header--info">{t('member.label.TermsAcceptionModal.header')}</div>
      </Modal.Header>
      <Modal.Body>
        <Container>
          <div className="divTerms">
            <div
              className="terms-content"
              data-testid="terms-acception-detail"
              dangerouslySetInnerHTML={{
                __html: terms ? (terms.termsDetailContents ? DOMPurify.sanitize(terms.termsDetailContents) : '') : '',
              }}
            />
          </div>
        </Container>
      </Modal.Body>
    </Modal>
  );
};

export default TermsAcceptionModal;
