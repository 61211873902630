import React, { useState, useEffect, ReactElement, useContext } from 'react';
import { Button, Image, Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import './RightSideApprovalList.scss';
import icoResult from '../../../icons/ico--result.svg';
import moment from 'moment';

import DOMPurify from 'dompurify';

import { User } from '../model/User';
import { ReservationResponse, VisitorResponse, QueryType, ReservationErrorDetail } from '../model/Reservation';
import ReservationService from '../services/ReservationService';
import ReservationApproveModal from '../components/ReservationApproveModal';
import { ApprovalStatusEnum, ImportedGoodsCodeEnum, DateFormat } from '../model/ReservationConstants';

import update from 'react-addons-update';
import InfiniteScroll from 'react-infinite-scroll-component';
import { CommonCode } from '../model/Code';
import { WorkplaceContent } from '../model/Workplace';
import { reservationDetailUtil } from '../utils/ReservationDetailUtil';
import { HistorySearchConditionModel } from '../model/ReservationTypes';
import { useHistory } from 'react-router-dom';
import { AuthContext } from '../../../App';
import { ActionType } from '../../../reducer/actions';

interface RightSideApprovalList {
  sessionUser: User;
  reservationService: ReservationService;
  commonCodeMap: Map<string, CommonCode[]> | undefined;
  isRigitSideShow: boolean;
  setRigitSideShow: Function;
  rightSideInfoType: string;
  isLoading: boolean;
  handleLoading: (value: boolean) => void;
  searchCondition: HistorySearchConditionModel | undefined;
}

const RightSideApprovalList: React.FC<RightSideApprovalList> = ({
  sessionUser,
  reservationService,
  commonCodeMap,
  isRigitSideShow,
  setRigitSideShow,
  rightSideInfoType,
  isLoading,
  handleLoading,
  searchCondition,
}: RightSideApprovalList): ReactElement => {
  const { t } = useTranslation();
  const { dispatch, state } = useContext(AuthContext);
  const languageCode = localStorage.getItem('lang') ? (localStorage.getItem('lang') as string) : 'ko';

  const [reservationList, setReservationList] = useState<ReservationResponse[]>([]);
  const [selectedIndex, setSelectedIndex] = useState<number>();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [pagingIndex, setPagingIndex] = useState<number>(0);
  const [hasMore, setHasMore] = useState<boolean>(true);

  const history = useHistory();

  const setLoading = (_isLoading: boolean) => {
    dispatch({ type: ActionType.LOADING, loading: { isLoading: _isLoading } });
  };
  const toggleErrorDetailMessage = (index: number, showDetailError: boolean) => {
    return update(reservationList, {
      [index]: {
        showDetailError: { $set: showDetailError },
      },
    });
  };
  const updateReservationList = (
    index: number,
    successYesOrNo: string,
    approvalStatus?: ApprovalStatusEnum,
    approvalMessage = '',
    errorDetailData?: ReservationErrorDetail
  ) => {
    if (successYesOrNo && successYesOrNo === 'Y') {
      return update(reservationList, {
        [index]: {
          approvalStatus: { $set: approvalStatus },
          approvalMessage: { $set: approvalMessage },
          lastUpdateDate: { $set: moment().format('YYYYMMDD') },
        },
      });
    } else {
      return update(reservationList, {
        [index]: {
          successYesOrNo: { $set: successYesOrNo },
          failMessage: { $set: approvalMessage },
          errorDetailData: { $set: errorDetailData },
        },
      });
    }
  };

  const addReservationList = (additionList: ReservationResponse[]) => {
    return update(reservationList, {
      $push: additionList,
    });
  };

  const handleItemClick = (index: number) => {
    setSelectedIndex(index);
    setShowModal(true);
    history.push(history.location.hash + '#approvalModal');
  };
  const handleShowDetailError = (index: number, showDetailError: boolean) => {
    setReservationList(reservationList => toggleErrorDetailMessage(index, !showDetailError));
  };
  const handleApprove = async (approvalStatus: ApprovalStatusEnum, index: number, reservation: ReservationResponse) => {
    setLoading(true);
    const response = await reservationService.approveReservation(reservation, approvalStatus);

    if (response && response.successYesOrNo === 'Y') {
      setReservationList(reservationList => updateReservationList(index, 'Y', approvalStatus));
      dispatch({ type: ActionType.REFRESH_NAV_BAR, refreshNavBar: !state.refreshNavBar });
    } else {
      setReservationList(reservationList =>
        updateReservationList(
          index,
          'N',
          approvalStatus,
          response.statusMessage ? response.statusMessage : undefined,
          response.data ? response.data : undefined
        )
      );
    }
    setLoading(false);
  };

  const handleModalClose = (approvalStatus: ApprovalStatusEnum | undefined, approvalMessage = '') => {
    history.goBack();
    approvalStatus &&
      selectedIndex !== undefined &&
      setReservationList(reservationList => updateReservationList(selectedIndex, 'Y', approvalStatus, approvalMessage));
  };

  const handleLoadMore = async () => {
    let response;
    if (searchCondition) {
      response = await reservationService.getApprovalList(sessionUser, pagingIndex, searchCondition);
    } else {
      response = await reservationService.getApprovalList(sessionUser, pagingIndex);
    }

    if (response && response.length > 0) {
      setReservationList(reservationList => addReservationList(response));
      setPagingIndex(pagingIndex + response.length);
      setHasMore(true);
    } else {
      setHasMore(false);
    }
  };

  const getAdditionalVisitor = (applyMemberId: string, applyMemberList: VisitorResponse[]) => {
    return applyMemberList.find(visitor => visitor.applyMemberId === applyMemberId)
      ? applyMemberList.length - 1
      : applyMemberList.length;
  };

  const getVisitorInfo = (reservation: ReservationResponse) => {
    let visitorCnt = 0;
    const visitorName = '';
    let hostName = '';

    if (reservation.applyMemberList) {
      visitorCnt = getAdditionalVisitor(reservation.applyMemberId, reservation.applyMemberList);
      if (reservation.hostEmployeeId !== sessionUser.id) {
        hostName = reservation.hostEmployeeName;
      }
      return reservation.applyMemberList.length > 1 ? (
        <span>
          <em>{reservation.applyMemberList[0].applyMemberName}</em>
          {t('reservation.label.RightSideDrawer.body.title.requestForMultiVisitor', {
            count: reservation.applyMemberList.length - 1,
            hostName: hostName === '' ? '' : '(' + hostName + ')',
          })}
        </span>
      ) : (
        <span>
          <em>{reservation.applyMemberList[0].applyMemberName}</em>
          {t('reservation.label.RightSideDrawer.body.title.request', {
            hostName: hostName === '' ? '' : '(' + hostName + ')',
          })}
        </span>
      );
    }
  };

  const getVisitWorkplace = (reservation: ReservationResponse) => {
    let workplace;
    if (reservation && reservation.workplaceContent && reservation.workplaceContent.length > 0) {
      const workplaceContents: WorkplaceContent[] = JSON.parse(reservation.workplaceContent);
      workplace = workplaceContents.find(
        workplace => workplace.languageCode.toUpperCase() === languageCode.toUpperCase()
      );
    }
    return workplace ? workplace.groupCompanyName.concat(' ', workplace.workplace) : reservation.visitWorkplace;
  };

  const getApplyDateStr = (applyDate: string) => {
    const diff = moment(moment().format(DateFormat.DATE_IF)).diff(moment(applyDate).format(DateFormat.DATE_IF), 'days');
    if (diff === 0) {
      return t('reservation.label.RightSideDrawer.body.applyDate.today');
    } else if (diff > 0 && diff <= 5) {
      return t('reservation.label.RightSideDrawer.body.applyDate.fromNow', {
        days: diff,
      });
    } else {
      return moment(applyDate).format(DateFormat.DATE_DISP);
    }
  };

  const getVisitPeriod = (fromDate, toDate) => {
    if (fromDate === toDate) {
      return moment(fromDate).format(DateFormat.DATE_DISP);
    } else if (
      moment(fromDate)
        .startOf('year')
        .isSame(moment(toDate).startOf('year'))
    ) {
      return moment(fromDate)
        .format(DateFormat.DATE_DISP)
        .concat(' ~ ', moment(toDate).format(DateFormat.MONTH_AND_DAY_DISP));
    } else {
      return moment(fromDate)
        .format(DateFormat.DATE_DISP)
        .concat(' ~ ', moment(toDate).format(DateFormat.DATE_DISP));
    }
  };

  const getExtraInfo = (reservation: ReservationResponse) => {
    const info: string[] = [];
    let othersCnt = 0;

    if (reservation.importedGoodsSummary) {
      reservation.importedGoodsSummary.map(goods => {
        if (goods.importedGoodsCode === ImportedGoodsCodeEnum.NOTEBOOK) {
          info.push(
            t('reservation.label.RightSideDrawer.body.extraInfo.laptop', {
              count: Number(goods.importedGoodsCounts),
            })
          );
        } else {
          othersCnt += Number(goods.importedGoodsCounts);
        }
      });

      othersCnt > 0 &&
        info.push(
          t('reservation.label.RightSideDrawer.body.extraInfo.others', {
            count: othersCnt,
          })
        );
    }

    if (reservation.applyMemberList) {
      let parkingCnt = 0;
      reservation.applyMemberList.map(visitor => {
        visitor.applyMemberCarNumber && parkingCnt++;
      });

      parkingCnt > 0 &&
        info.push(
          t('reservation.label.RightSideDrawer.body.extraInfo.parking', {
            count: parkingCnt,
          })
        );
    }

    if (info && info.length > 0) {
      return info.join(', ');
    }
  };

  const isIntegrateApproval = (workplaceStr: string | undefined) => {
    let isIntegrateApproval = false;
    if (workplaceStr) {
      const workplace = JSON.parse(workplaceStr);
      isIntegrateApproval = workplace.integrateApprovalYesorno === 'Y' ? true : false;
    }
    return isIntegrateApproval;
  };

  /* eslint-disable */
  useEffect(() => {
    const getInitialReservationList = async () => {
      if (sessionUser) {
        handleLoading(true);
        if (searchCondition) {
          return await reservationService.getApprovalList(sessionUser, 0, searchCondition);
        } else {
          return await reservationService.getApprovalList(sessionUser, 0);
        }
      }
    };
    isRigitSideShow &&
      sessionUser &&
      getInitialReservationList()
        .then(res => {
          if (res && res.length > 0 && rightSideInfoType === QueryType.APPROVAL) {
            setReservationList(res);
            setPagingIndex(0 + res.length);
            setHasMore(true);
          } else {
            setReservationList([]);
            setPagingIndex(0);
            setHasMore(false);
          }
        })
        .finally(() => {
          handleLoading(false);
        });
    if (!history.location.hash.includes('#rightSide')) history.push(history.location.hash + '#rightSide');
  }, [isRigitSideShow, searchCondition]);
  /* eslint-enable */

  /* eslint-disable */
  useEffect(() => {
    const location = history.location;
    if (!location.hash.includes('#approvalModal')) {
      setShowModal(false);
    }
  }, [history.location]);

  /* eslint-enable */

  const getExceptionMessage = (response: any): string => {
    let resultCode = 'reservation.message.exception.approveFailed';
    if (response.failMessage && response.failMessage.startsWith('ALREADY.APPROVE')) {
      resultCode = 'reservation.message.exception.' + response.failMessage;
      setRigitSideShow(!isRigitSideShow);
    }

    return t(resultCode, {
      interpolation: { escapeValue: false },
    });
  };

  return (
    <div>
      {reservationList && reservationList.length > 0 ? (
        <InfiniteScroll
          dataLength={reservationList.length}
          next={handleLoadMore}
          hasMore={hasMore}
          loader={<Spinner className="infiniteScrollLoader" animation="border" />}
          className="drawer__list"
          scrollableTarget="drawerDiv"
          scrollThreshold={0.9}
        >
          {reservationList.map((value, index) => {
            return (
              <li
                id={value.visitRequestId + index}
                key={value.visitRequestId + index}
                className={value.approvalStatus === ApprovalStatusEnum.IP016002 ? 'drawer__status--ready' : ''}
                data-testid={'selectedReservation' + index}
                onClick={() => handleItemClick(index)}
              >
                <p className="drawer__list--header">
                  {getVisitorInfo(value)}
                  <span>{getApplyDateStr(value.applyDate)}</span>
                </p>
                {value.approvalStatus === ApprovalStatusEnum.IP016002 ? (
                  <div>
                    <p className="drawer__list--info">
                      {getVisitWorkplace(value)} / {getVisitPeriod(value.fromVisitPeriod, value.toVisitPeriod)}
                    </p>
                    <p className="drawer__list--item">{getExtraInfo(value)}</p>
                    {isIntegrateApproval(value.workplaceInfo) ? (
                      <div className="drawer__chip">
                        <em className="drawer__chip--request">
                          <i className="material-icons">more_horiz</i>
                          <span>
                            {t(reservationDetailUtil.getStatusDesc(value.approvalStatus, value.workplaceInfo))}
                          </span>
                        </em>
                      </div>
                    ) : (
                      <div className="drawer__list--button">
                        <Button
                          type="button"
                          className="drawer__button--cancel"
                          data-testid="btn-cancel-test"
                          variant="dark"
                          onClick={(e: any) => {
                            e.stopPropagation();
                            e.preventDefault();
                            handleApprove(ApprovalStatusEnum.IP016004, index, value);
                          }}
                        >
                          <span>{t('reservation.label.RightSideDrawer.body.button.reject')}</span>
                        </Button>
                        <Button
                          type="button"
                          className="drawer__button--confirm"
                          data-testid="btn-confirm-test"
                          variant="primary"
                          onClick={(e: any) => {
                            e.stopPropagation();
                            e.preventDefault();
                            handleApprove(ApprovalStatusEnum.IP016003, index, value);
                          }}
                        >
                          <i className="material-icons pointer">check</i>
                          <span>{t('reservation.label.RightSideDrawer.body.button.approve')}</span>
                        </Button>
                        {value.successYesOrNo && value.successYesOrNo === 'N' && (
                          <Button
                            type="button"
                            className="drawer__button--detail"
                            data-testid="btn-detail-test"
                            variant="primary"
                            onClick={(e: any) => {
                              e.stopPropagation();
                              e.preventDefault();
                              handleShowDetailError(index, value.showDetailError || false);
                            }}
                          >
                            {value.showDetailError ? t('common.label.hide') : t('common.label.showDetail')}
                          </Button>
                        )}
                      </div>
                    )}
                    {value.successYesOrNo && value.successYesOrNo === 'N' && value.showDetailError && (
                      <p className="error-detail-content">
                        {t('common.message.AlertModal.errorDetail', {
                          systemName:
                            (value && value.errorDetailData && value.errorDetailData.target) ||
                            t('common.message.AlertModal.defaultSystemName'),
                          statusCode:
                            (value && value.errorDetailData && value.errorDetailData.detailStatusCode) ||
                            t('common.message.AlertModal.defaultStatusCode'),
                          statusMessage:
                            (value && value.errorDetailData && value.errorDetailData.detailStatusMessage) ||
                            t('common.message.AlertModal.defaultStatusMessage'),
                          errorMessage:
                            (value &&
                              value.errorDetailData &&
                              value.errorDetailData.displayMessage &&
                              value.errorDetailData.displayMessage.meaning) ||
                            t('common.message.AlertModal.defaultErrorMessage'),
                        })}
                      </p>
                    )}
                    {value.successYesOrNo && value.successYesOrNo === 'N' && (
                      <p
                        className="drawer__msg--alert"
                        dangerouslySetInnerHTML={{
                          __html: DOMPurify.sanitize(getExceptionMessage(value)),
                        }}
                      ></p>
                    )}
                  </div>
                ) : (
                  <div>
                    <div className="drawer__chip" data-testid="drawer__chip">
                      {value.approvalStatus === ApprovalStatusEnum.IP016003 && (
                        <em className="drawer__chip--confirm">
                          <i className="material-icons">check</i>
                          <span>{t(reservationDetailUtil.getStatusDesc(value.approvalStatus))}</span>
                        </em>
                      )}
                      {value.approvalStatus === ApprovalStatusEnum.IP016004 && (
                        <em className="drawer__chip--cancel">
                          <i className="material-icons">warning</i>
                          <span>{t(reservationDetailUtil.getStatusDesc(value.approvalStatus))}</span>
                        </em>
                      )}
                    </div>
                    {value.approvalMessage && <p className="drawer__msg--default">{value.approvalMessage}</p>}
                  </div>
                )}
              </li>
            );
          })}
        </InfiniteScroll>
      ) : (
        !isLoading && (
          <div className="drawer__none">
            <Image src={icoResult} className="ico--result" />
            <p>{t('reservation.message.info.query.nodata')}</p>
          </div>
        )
      )}
      {showModal && selectedIndex !== undefined && (
        <ReservationApproveModal
          reservation={reservationList[selectedIndex]}
          sessionUser={sessionUser}
          show={showModal}
          commonCodeMap={commonCodeMap}
          onHide={handleModalClose}
          reservationService={reservationService}
        />
      )}
    </div>
  );
};

export default RightSideApprovalList;
