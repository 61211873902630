import React, { ReactElement, useState } from 'react';
import { Modal, ButtonToolbar, Button } from 'react-bootstrap';

import { useTranslation } from 'react-i18next';
import DOMPurify from 'dompurify';

import { ReservationResponse } from '../model/Reservation';
import { User } from '../model/User';

import { ModalVisitors } from './ModalVisitors';
import ModalVisitToTarget from './ModalVisitToTarget';

import { ApprovalStatusEnum, MemberTypeCode } from '../model/ReservationConstants';
import './ReservationDetailModal.scss';
import { CommonCode } from '../model/Code';
import ReservationService from '../services/ReservationService';

export interface ReservationDetailModal {
  reservation: ReservationResponse;
  sessionUser: User;
  show: boolean;
  commonCodeMap: Map<string, CommonCode[]> | undefined;
  selectedIndex: number;
  handleShowConfirmModal: Function;
  onHide: (status?: ApprovalStatusEnum) => void;
  reservationService?: ReservationService;
  rightSideInfoType: string;
}

export const ReservationDetailModal: React.FC<ReservationDetailModal> = ({
  reservation,
  sessionUser,
  show,
  commonCodeMap,
  onHide,
  selectedIndex,
  handleShowConfirmModal,
  reservationService,
  rightSideInfoType,
}: ReservationDetailModal): ReactElement => {
  const { t } = useTranslation();
  const onCopyReservation = () => {
    handleShowConfirmModal(selectedIndex);
  };
  let beforeApproval = false;
  let integrateApproval = false;
  const hideCancelBtn =
    reservation.applyMemberDivision === MemberTypeCode.NO_MEM ||
    reservation.approvalStatus === ApprovalStatusEnum.IP016005 ||
    reservation.approvalStatus === ApprovalStatusEnum.IP016001;

  const [successYesOrNo, setSuccessYesOrNo] = useState<string>();

  const getCancelAble = (reservation: ReservationResponse): boolean => {
    if (reservation.approvalStatus == ApprovalStatusEnum.IP016002) {
      beforeApproval = true;
    }
    if (reservation.workplaceInfo && JSON.parse(reservation.workplaceInfo).integrateApprovalYesorno == 'Y') {
      integrateApproval = true;
    }
    if (beforeApproval && !integrateApproval) {
      return true;
    }
    return false;
  };

  const getCancelApprovalQuote = () => {
    if (beforeApproval === false) {
      return <span className="cancelQuote">{t('reservation.label.ReservationDetailModal.message.afterProcess')}</span>;
    }
    if (integrateApproval === true) {
      return <span className="cancelQuote">{t('reservation.label.ReservationDetailModal.message.workplaceDeny')}</span>;
    }
  };

  const handleCancel = async () => {
    if (reservationService) {
      const response = await reservationService.cancelReservation(reservation.visitRequestId);
      if (response) {
        onHide(ApprovalStatusEnum.IP016005);
      } else {
        setSuccessYesOrNo('N');
      }
    }
  };

  return (
    <Modal
      id="reservation-detail-modal"
      data-testid="reservation-detail-modal"
      onHide={onHide}
      centered={true}
      show={show}
      className="modal-z-index reservation-modal"
    >
      <Modal.Header closeButton>
        <Modal.Title></Modal.Title>
      </Modal.Header>
      <Modal.Body data-testid="modalbody">
        <ModalVisitToTarget
          reservation={reservation}
          sessionUser={sessionUser}
          showRequestor={false}
          commonCodeMap={commonCodeMap}
          rightSideInfoType={rightSideInfoType}
          onCopyReservation={onCopyReservation}
        />
        <ModalVisitors reservation={reservation} sessionUser={sessionUser} commonCodeMap={commonCodeMap} />
        {hideCancelBtn ? (
          <ButtonToolbar>
            <Button
              className="approveConformButton"
              name="ok"
              data-testid="ok-button"
              variant="primary"
              onClick={() => onHide()}
            >
              <span>{t('reservation.label.button.ok')}</span>
            </Button>
          </ButtonToolbar>
        ) : (
          <ButtonToolbar>
            <Button
              id="cancelBtn"
              name="cancel"
              data-testid="cancel-button"
              variant="dark"
              disabled={!getCancelAble(reservation)}
              onClick={handleCancel}
            >
              <span>{t('reservation.label.button.cancel')}</span>
            </Button>
            <Button id="confirmBtn" name="ok" data-testid="ok-button" variant="primary" onClick={() => onHide()}>
              <span>{t('reservation.label.button.ok')}</span>
            </Button>
            {getCancelApprovalQuote()}
            {successYesOrNo && successYesOrNo === 'N' && (
              <p
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(
                    t('reservation.label.ReservationDetailModal.message.cancelFailed', {
                      interpolation: { escapeValue: false },
                    })
                  ),
                }}
              ></p>
            )}
          </ButtonToolbar>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default ReservationDetailModal;
