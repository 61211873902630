import React, { ReactElement, useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Form,
  Col,
  Button,
  Card,
  Row,
  Toast,
  Collapse,
  InputGroup,
  FormControl,
  Image,
  Dropdown,
} from 'react-bootstrap';
import Select, { components } from 'react-select';
import { MemberChangeType } from '../../model/MemberChangeType.enum';
import {
  MemberInfoChange,
  MemberChange,
  FAIL_ICON,
  CHECK_ICON,
  SelectItem,
  TOAST_ID_TYPE,
  TOAST_CLASS_TYPE,
} from '../../model/MemberChange';
import './EmployeeMemberInfoForm.scss';
import InputMask from 'react-input-mask';

import MemberService from '../../../reservation/services/MemberService';
import MemberInfoService from '../../services/MemberInfoService';
import { Formik } from 'formik';
import * as yup from 'yup';

import MemberRegistrationConstants from '../../model/MemberRegistrationConstants';
import { AuthContext } from '../../../../App';
import { ActionType } from '../../../../reducer/actions';
import searchBlack24Icon from '../../../../icons/search_black-24px.svg';
import dropDownPersonIcon from '../../../../icons/dropdown_person.svg';
import noResultIcon from '../../../../icons/no_result.svg';
import enter24Icon from '../../../../icons/subdirectory_arrow_left-24px.svg';
import close24Icon from '../../../../icons/close-24px.svg';

import { SearchVisitToInfo } from '../../../reservation/model/Member';
import WorkplaceService from '../../../reservation/services/WorkplaceService';
import { SearchUser } from '../../../reservation/model/ReservationTypes';
import { User } from '../../../reservation/model/User';
import { UserTypeEnum } from '../../../reservation/model/ReservationConstants';
import SessionService from '../../../../service/SessionService';
import { useHistory } from 'react-router-dom';

export interface EmployeeMemberInfoFormProps {
  memberInfo: MemberChange;
}

interface Delegate {
  id: string;
  name: string;
  empType: string;
  emailAddress: string;
}

export const EmployeeMemberInfoForm: React.FC<EmployeeMemberInfoFormProps> = (
  props: EmployeeMemberInfoFormProps
): ReactElement => {
  const { t } = useTranslation();
  const { dispatch } = useContext(AuthContext);
  const memberInfoService = new MemberInfoService();
  const memberServiece = new MemberService();
  const workplaceService = new WorkplaceService();
  const sessionService = new SessionService();

  const [passwordInputFormOpen, setPasswordInputFormOpen] = useState(false);
  const [mainWorkplaceFormOpen, setMainWorkplaceFormOpen] = useState(false);
  const [serialInputFormOpen, setSerialInputFormOpen] = useState(false);
  const [delegateInputFormOpen, setDelegateInputFormOpen] = useState(false);

  const [workplaceList, setWorkplaceList] = useState<SelectItem[]>([]);
  const [mainWorkplaceInfo, setMainWorkplaceInfo] = useState<SelectItem>({ value: '-1', label: '' });
  const [serialNo, setSerialNo] = useState(props.memberInfo.notebookSerialNo || '');

  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [toastId, setToastId] = useState(TOAST_ID_TYPE.FAIL);
  const [toastClassName, setToastClassName] = useState(TOAST_CLASS_TYPE.PASSWORD);
  const [toastIcon, setToastIcon] = useState('');
  const [passwordError, setPasswordError] = useState('');

  const [allDelegateList, setAllDelegateList] = useState<Delegate[] | undefined>();
  const [showSearchResultList, setShowSearchResultList] = useState<boolean>(false);
  const [delegateMemberId, setDelegateMemberId] = useState(props.memberInfo.delegateMemberId || '');
  const [delegateMemberName, setDelegateMemberName] = useState<string | null>(
    props.memberInfo.delegateMemberName || ''
  );
  const [delegateMemberPositionName, setDelegateMemberPositionName] = useState<string | null>(
    props.memberInfo.delegateMemberPositionName || ''
  );
  const [delegateEditable, setDelegateEditable] = useState<boolean>(false);
  const [delegateVisible, setDelegateVisible] = useState<boolean>(false);
  const history = useHistory();

  const clearPasswordError = (): void => {
    if (passwordError !== '') {
      setPasswordError('');
    }
  };
  const setLoading = (_isLoading: boolean) => {
    dispatch({ type: ActionType.LOADING, loading: { isLoading: _isLoading } });
  };

  const findToastMessage = (idType: TOAST_ID_TYPE, classType: TOAST_CLASS_TYPE): string => {
    switch (idType) {
      case TOAST_ID_TYPE.SUCCESS:
        switch (classType) {
          case TOAST_CLASS_TYPE.PASSWORD:
            return t('member.label.memberInfoForm.password.updateSuccess');
          case TOAST_CLASS_TYPE.PHONE:
            return t('member.label.memberInfoForm.phoneNumber.updateSuccess');
          case TOAST_CLASS_TYPE.NOTEBOOK:
            return t('member.label.memberInfoForm.serialNo.updateSuccess');
          case TOAST_CLASS_TYPE.WORKPLACE:
            return t('member.label.memberInfoForm.mainWorkplace.updateSuccess');
          case TOAST_CLASS_TYPE.DELEGATE:
            return t('member.label.memberInfoForm.delegateMember.updateSuccess');
          default:
            return '';
        }
      case TOAST_ID_TYPE.FAIL:
        switch (classType) {
          case TOAST_CLASS_TYPE.PASSWORD:
            return t('member.label.memberInfoForm.password.updateFail');
          case TOAST_CLASS_TYPE.PHONE:
            return t('member.label.memberInfoForm.phoneNumber.updateFail');
          case TOAST_CLASS_TYPE.NOTEBOOK:
            return t('member.label.memberInfoForm.serialNo.updateFail');
          case TOAST_CLASS_TYPE.WORKPLACE:
            return t('member.label.memberInfoForm.mainWorkplace.updateFail');
          case TOAST_CLASS_TYPE.DELEGATE:
            return t('member.label.memberInfoForm.delegateMember.updateFail');
          default:
            return '';
        }
      default:
        return '';
    }
  };
  const setToast = (idType: TOAST_ID_TYPE, classType: TOAST_CLASS_TYPE) => {
    setShowToast(false);
    setToastId(idType);
    setToastClassName(classType);
    idType === TOAST_ID_TYPE.SUCCESS ? setToastIcon(CHECK_ICON) : setToastIcon(FAIL_ICON);
    setToastMessage(findToastMessage(idType, classType));
    setShowToast(true);
  };

  const passwordSchema = yup.object({
    password: yup.string().required(' '),
    newPassword: yup
      .string()
      .required(' ')
      .matches(MemberRegistrationConstants.REGEXP_PASSWORD, t('member.message.frontEnd.password.common')),
    newPasswordConfirm: yup
      .string()
      .required(' ')
      .oneOf([yup.ref('newPassword'), null], t('member.message.frontEnd.password.common'))
      .test('password-contains-email-id', t('member.message.frontEnd.password.containsEmailId'), function(
        value: string
      ) {
        const email = props.memberInfo.emailAddress;
        const emailId = email ? email.split('@')[0] : '';
        return !(value && value.includes(emailId));
      }),
  });

  const dropdownIndicator = (props: any) => {
    return (
      components.DropdownIndicator && (
        <components.DropdownIndicator {...props}>
          <i className="material-icons">{props.selectProps.menuIsOpen ? 'arrow_drop_up' : 'arrow_drop_down'}</i>
        </components.DropdownIndicator>
      )
    );
  };
  /*eslint-disable*/
  const onDelegateSearchPressed = async (e, values, setFieldValue) => {
    if (e.key === 'Enter') {
      e.stopPropagation();
      e.preventDefault();
      if (!values.searchName.trim() || !values.searchPhoneNumber) {
        return;
      }
      let delegateList: Delegate[] | undefined;

      setLoading(true);

      const searchCond: SearchUser = {
        name: values.searchName,
        mobile: values.searchPhoneNumber,
      };

      const response: any = await memberServiece.getSearchUsers([], searchCond);

      setLoading(false);

      if (response) {
        delegateList = response.filter((user: User, index: number) => {
          return (
            user.id !== props.memberInfo.memberId + '' &&
            user.type === UserTypeEnum.LGMEMBER &&
            user.companyCode === props.memberInfo.companyCode
          );
        });
      }

      if (delegateList && delegateList.length === 1) {
        setFieldValue('delegateMemberId', delegateList[0].id);
        setDelegateMemberName(delegateList[0].name);
        setDelegateMemberPositionName(delegateList[0].empType);
        setAllDelegateList(undefined);
      } else {
        setAllDelegateList(delegateList);
        setShowSearchResultList(true);
      }
    }
  };

  useEffect(() => {
    memberInfoService.getWorkplaceListByGroupCompanyId(props.memberInfo.companyCode).then(response => {
      let res = JSON.parse(JSON.stringify(response));

      if (res) {
        let tempWorkplaceList: SelectItem[] = [
          {
            value: '-1',
            label: '',
          },
        ];
        setDelegateEditable(false);
        setDelegateVisible(false);
        setMainWorkplaceInfo(tempWorkplaceList[0]);
        res.data.map(workplaceInfo => {
          tempWorkplaceList.push({
            value: workplaceInfo.workplaceId,
            label: props.memberInfo.companyName + ' ' + workplaceInfo.workplace,
          });
          if (workplaceInfo.workplaceId === props.memberInfo.mainWorkplaceId || '') {
            setMainWorkplaceInfo({
              value: workplaceInfo.workplaceId,
              label: props.memberInfo.companyName + ' ' + workplaceInfo.workplace,
            });

            workplaceService.getWorkplaceById(props.memberInfo.mainWorkplaceId).then(workplaceResponse => {
              workplaceResponse.length > 0 &&
                setDelegateEditable(
                  workplaceResponse[0].integrateApprovalYesorno && workplaceResponse[0].integrateApprovalYesorno === 'N'
                );
            });

            setDelegateVisible(true);
          }
        });

        setWorkplaceList(tempWorkplaceList);
      }
    });
  }, []);
  /* eslint-enable */

  return (
    <div id="EmployeeMemberInfoForm">
      <Card>
        <Card.Header>
          <Row>
            <Col sm={10}>
              <Row className="info-item-title">{t('member.label.memberInfoForm.email.titleLabel')}</Row>
              <Row className="info-item-desc" id="currentEmailAddress" data-testid="currentEmailAddress">
                {props.memberInfo?.emailAddress}
              </Row>
            </Col>
            <Col sm={2}></Col>
          </Row>
        </Card.Header>
      </Card>
      <Form.Row className="seperation-line" />
      <Formik
        validateOnMount
        initialValues={{
          password: '',
          newPassword: '',
          newPasswordConfirm: '',
        }}
        onSubmit={async (values, { setFieldError, resetForm }) => {
          const memberChangeInfo: MemberInfoChange = {
            memberChangeType: MemberChangeType.PASSWORD,
            currentValue: values.password,
            changeValue: values.newPassword,
          };
          setLoading(true);
          const submitStatus = await memberInfoService.putMemberInfo(memberChangeInfo);
          setLoading(false);
          if (submitStatus.successOrNot === 'Y') {
            resetForm();
            setPasswordInputFormOpen(false);
            setToast(TOAST_ID_TYPE.SUCCESS, TOAST_CLASS_TYPE.PASSWORD);
            sessionService.deleteSessionInfo();
            history.push('/login');
          } else {
            if (submitStatus.statusCode === 'INVALID.CURRENT.PASSWORD') {
              setPasswordError(t('member.message.frontEnd.password.currentPasswordNotMatch'));
            } else if (submitStatus.statusCode === 'DUPLICATION.PASSWORD') {
              setPasswordError(t('member.message.frontEnd.password.passwordDuplicated'));
            } else {
              setToast(TOAST_ID_TYPE.FAIL, TOAST_CLASS_TYPE.PASSWORD);
            }
          }
        }}
        validationSchema={passwordSchema}
      >
        {({
          values,
          errors,
          isSubmitting,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldError,
          isValid,
          resetForm,
          initialStatus,
        }): any => (
          <Card>
            <Card.Header>
              <Row>
                <Col sm={10}>
                  <Row className="info-item-title">{t('member.label.memberInfoForm.password.titleLabel')}</Row>
                </Col>
                <Col sm={2}>
                  <Button
                    onClick={() => {
                      setPasswordInputFormOpen(!passwordInputFormOpen);
                      clearPasswordError();
                      resetForm();
                    }}
                    variant="link"
                    className="toggle-button"
                    id="togglePassword"
                    data-testid="togglePassword"
                  >
                    {passwordInputFormOpen
                      ? t('member.label.memberInfoForm.common.formHideLabel')
                      : t('member.label.memberInfoForm.common.formOpenLabel')}
                  </Button>
                </Col>
              </Row>
            </Card.Header>
            <Collapse in={passwordInputFormOpen} data-testid="passwordCollapse">
              <Card.Body>
                <Form onSubmit={handleSubmit}>
                  <Form.Group>
                    <Form.Control
                      type="password"
                      placeholder={t('member.label.memberInfoForm.password.placeHolder')}
                      id="password"
                      name="password"
                      data-testid="password"
                      value={values.password}
                      required
                      onChange={
                        passwordError === ''
                          ? handleChange
                          : () => {
                              clearPasswordError();
                            }
                      }
                      onBlur={handleBlur}
                      isInvalid={!!errors.password}
                    ></Form.Control>
                    <Form.Control.Feedback id="passwordFeedback" data-testid="passwordFeedback" type="invalid">
                      {errors.password}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group>
                    <Form.Control
                      type="password"
                      placeholder={t('member.label.memberInfoForm.password.placeHolder2')}
                      id="newPassword"
                      name="newPassword"
                      data-testid="newPassword"
                      value={values.newPassword}
                      required
                      onChange={
                        passwordError === ''
                          ? handleChange
                          : () => {
                              clearPasswordError();
                            }
                      }
                      onBlur={handleBlur}
                      isInvalid={!!errors.newPassword}
                    />
                    <Form.Control
                      type="password"
                      placeholder={t('member.label.memberInfoForm.password.placeHolder3')}
                      id="newPasswordConfirm"
                      name="newPasswordConfirm"
                      data-testid="newPasswordConfirm"
                      value={values.newPasswordConfirm}
                      required
                      onChange={
                        passwordError === ''
                          ? handleChange
                          : () => {
                              clearPasswordError();
                            }
                      }
                      onBlur={handleBlur}
                      isInvalid={!!errors.newPasswordConfirm}
                    />
                    <Form.Control.Feedback id="newPasswordFeedback" data-testid="newPasswordFeedback" type="invalid">
                      {passwordError || errors.newPassword || errors.newPasswordConfirm}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Button
                    id="passwordChangeButton"
                    name="passwordChangeButton"
                    data-testid="passwordChangeButton"
                    type="submit"
                    disabled={isSubmitting || !isValid}
                    className="submit-button top-empty-space"
                  >
                    {t('member.label.memberInfoForm.password.buttonLabel')}
                  </Button>
                </Form>
              </Card.Body>
            </Collapse>
          </Card>
        )}
      </Formik>
      <Form.Row className="seperation-line" />
      <Card>
        <Card.Header>
          <Row>
            <Col sm={10}>
              <Row className="info-item-title">{t('member.label.memberInfoForm.memberName.titleLabel')}</Row>
              <Row className="info-item-desc" id="currentMemberName" data-testid="currentMemberName">
                {props.memberInfo.memberName}
              </Row>
              <Row className="info-item-title" id="currentDepartmentName" data-testid="currentDepartmentName">
                {props.memberInfo.companyName + ' ' + props.memberInfo.departmentName}
              </Row>
            </Col>
            <Col sm={2}></Col>
          </Row>
        </Card.Header>
      </Card>
      <Form.Row className="seperation-line" />
      <Formik
        validateOnMount
        initialValues={{
          mainWorkplaceInfo: mainWorkplaceInfo,
        }}
        onSubmit={async (values, { setFieldValue }) => {
          let memberInfoChange: MemberInfoChange = {
            memberChangeType: MemberChangeType.MAIN_WORKPLACE_ID,
            currentValue: null,
            changeValue:
              values.mainWorkplaceInfo === undefined || values.mainWorkplaceInfo.value === '-1'
                ? ''
                : values.mainWorkplaceInfo.value,
          };
          setLoading(true);
          let submitStatus = await memberInfoService.putMemberInfo(memberInfoChange);
          setLoading(false);
          if (submitStatus.successOrNot === 'Y') {
            setDelegateEditable(false);
            /*eslint-disable*/
            if (values.mainWorkplaceInfo.value !== '-1') {
              workplaceService
                .getWorkplaceById(values.mainWorkplaceInfo.value)
                .then(workplaceResponse => {
                  workplaceResponse.length > 0 &&
                    setDelegateEditable(
                      workplaceResponse[0].integrateApprovalYesorno &&
                        workplaceResponse[0].integrateApprovalYesorno === 'N'
                    );
                })
                .finally(() => {
                  setDelegateVisible(true);
                });
            } else {
              memberInfoChange = {
                memberChangeType: MemberChangeType.DELEGATE_MEMBER_ID,
                currentValue: null,
                changeValue: '',
              };
              setLoading(true);
              submitStatus = await memberInfoService.putMemberInfo(memberInfoChange);
              setLoading(false);
              if (submitStatus.successOrNot === 'Y') {
                setDelegateMemberId('');
                setDelegateMemberName(null);
                setDelegateMemberPositionName(null);
                setDelegateInputFormOpen(false);
                setToast(TOAST_ID_TYPE.SUCCESS, TOAST_CLASS_TYPE.DELEGATE);
              } else {
                setToast(TOAST_ID_TYPE.FAIL, TOAST_CLASS_TYPE.DELEGATE);
              }

              setDelegateVisible(false);
            }
            /*eslint-enable*/
            setMainWorkplaceInfo(
              values.mainWorkplaceInfo === undefined || values.mainWorkplaceInfo.value === '-1'
                ? {
                    value: '-1',
                    label: '',
                  }
                : {
                    value: values.mainWorkplaceInfo.value,
                    label: values.mainWorkplaceInfo.label,
                  }
            );
            setFieldValue('mainWorkplaceInfo', values.mainWorkplaceInfo);
            setMainWorkplaceFormOpen(false);

            setToast(TOAST_ID_TYPE.SUCCESS, TOAST_CLASS_TYPE.WORKPLACE);
          } else {
            setToast(TOAST_ID_TYPE.FAIL, TOAST_CLASS_TYPE.WORKPLACE);
          }
        }}
      >
        {({ values, errors, isSubmitting, handleChange, handleBlur, handleSubmit, resetForm, setFieldValue }): any => (
          <Card>
            <Card.Header>
              <Row>
                <Col sm={10}>
                  <Row className="info-item-title">{t('member.label.memberInfoForm.mainWorkplace.titleLabel')}</Row>
                  <Row className="info-item-desc" id="currentMainWorkplace" data-testid="currentMainWorkplace">
                    {mainWorkplaceInfo?.label}
                  </Row>
                </Col>
                <Col sm={2}>
                  <Button
                    onClick={() => {
                      setMainWorkplaceFormOpen(!mainWorkplaceFormOpen);
                      setFieldValue('mainWorkplaceInfo', mainWorkplaceInfo);
                    }}
                    variant="link"
                    className={'toggle-button' + (mainWorkplaceInfo?.value === '-1' ? '' : ' verical-align-bottom')}
                    id="toggleMainWorkplace"
                    data-testid="toggleMainWorkplace"
                  >
                    {mainWorkplaceFormOpen
                      ? t('member.label.memberInfoForm.common.formHideLabel')
                      : t('member.label.memberInfoForm.common.formOpenLabel')}
                  </Button>
                </Col>
              </Row>
            </Card.Header>
            <Collapse in={mainWorkplaceFormOpen} data-testid="mainWorkplaceCollapse">
              <Card.Body>
                <Form onSubmit={handleSubmit}>
                  <Form.Group>
                    <Select
                      name="mainWorkplaceInfo"
                      id="mainWorkplaceInfo"
                      isClearable={false}
                      isSearchable={false}
                      options={workplaceList}
                      value={values.mainWorkplaceInfo}
                      onChange={e => {
                        setFieldValue('mainWorkplaceInfo', e);
                      }}
                      defaultValue={mainWorkplaceInfo === undefined ? null : mainWorkplaceInfo}
                      className="react-select-container"
                      classNamePrefix="react-select"
                      components={{
                        DropdownIndicator: dropdownIndicator,
                      }}
                      theme={theme => ({
                        ...theme,
                        borderRadius: 0,
                        colors: {
                          ...theme.colors,
                          primary25: 'rgba(67, 144, 153, 0.1)',
                          primary: 'white',
                        },
                      })}
                    />
                    <Form.Control.Feedback
                      id="mainWorkplaceFeedback"
                      data-testid="mainWorkplaceFeedback"
                      type="invalid"
                    >
                      {values.mainWorkplaceInfo.value === mainWorkplaceInfo.value &&
                        t('member.label.memberInfoForm.common.noChange')}
                    </Form.Control.Feedback>
                    <Button
                      id="mainWorkplaceChangeButton"
                      name="mainWorkplaceChangeButton"
                      data-testid="mainWorkplaceChangeButton"
                      type="submit"
                      className="submit-button top-empty-space"
                      disabled={isSubmitting || values.mainWorkplaceInfo.value === mainWorkplaceInfo.value}
                    >
                      {t('member.label.memberInfoForm.mainWorkplace.buttonLabel')}
                    </Button>
                  </Form.Group>
                </Form>
              </Card.Body>
            </Collapse>
          </Card>
        )}
      </Formik>
      <Form.Row className="seperation-line" />

      <Formik
        validateOnMount
        initialValues={{
          serialNo: props.memberInfo.notebookSerialNo || '',
        }}
        onSubmit={async (values, { resetForm }) => {
          const memberInfoChange: MemberInfoChange = {
            memberChangeType: MemberChangeType.NOTEBOOK_SERIAL_NO,
            currentValue: null,
            changeValue: values.serialNo.trim(),
          };
          setLoading(true);
          const submitStatus = await memberInfoService.putMemberInfo(memberInfoChange);
          setLoading(false);
          if (submitStatus.successOrNot === 'Y') {
            setSerialNo(values.serialNo.trim());
            resetForm({ values: { serialNo: serialNo || '' } });
            setSerialInputFormOpen(false);
            setToast(TOAST_ID_TYPE.SUCCESS, TOAST_CLASS_TYPE.NOTEBOOK);
          } else {
            setToast(TOAST_ID_TYPE.FAIL, TOAST_CLASS_TYPE.NOTEBOOK);
          }
        }}
      >
        {({ values, errors, isSubmitting, handleChange, handleBlur, handleSubmit, resetForm }): any => (
          <Card>
            <Card.Header>
              <Row>
                <Col sm={10}>
                  <Row className="info-item-title">{t('member.label.memberInfoForm.serialNo.titleLabel')}</Row>
                  <Row className="info-item-desc" id="currentSerialNo" data-testid="currentSerialNo">
                    {serialNo}
                  </Row>
                </Col>
                <Col sm={2}>
                  <Button
                    onClick={() => {
                      setSerialInputFormOpen(!serialInputFormOpen);
                      resetForm({ values: { serialNo: serialNo } });
                    }}
                    variant="link"
                    className={'toggle-button' + (serialNo === '' ? '' : ' verical-align-bottom')}
                    id="toggleSerialNo"
                    data-testid="toggleSerialNo"
                  >
                    {serialInputFormOpen
                      ? t('member.label.memberInfoForm.common.formHideLabel')
                      : t('member.label.memberInfoForm.common.formOpenLabel')}
                  </Button>
                </Col>
              </Row>
            </Card.Header>
            <Collapse in={serialInputFormOpen} data-testid="serialNoCollapse">
              <Card.Body>
                <Form onSubmit={handleSubmit}>
                  <Form.Group>
                    <Form.Control
                      type="text"
                      placeholder={t('member.label.memberInfoForm.serialNo.placeHolder')}
                      id="serialNo"
                      name="serialNo"
                      data-testid="serialNo"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.serialNo}
                      isInvalid={values.serialNo === serialNo}
                    />
                    <Form.Control.Feedback id="serialNoFeedback" data-testid="serialNoFeedback" type="invalid">
                      {values.serialNo === serialNo && t('member.label.memberInfoForm.common.noChange')}
                    </Form.Control.Feedback>
                    <Button
                      id="serialNoChangeButton"
                      name="serialNoChangeButton"
                      data-testid="serialNoChangeButton"
                      type="submit"
                      className="submit-button top-empty-space"
                      disabled={isSubmitting || values.serialNo === serialNo}
                    >
                      {t('member.label.memberInfoForm.serialNo.buttonLabel')}
                    </Button>
                  </Form.Group>
                </Form>
              </Card.Body>
            </Collapse>
          </Card>
        )}
      </Formik>
      <Form.Row className="seperation-line" />
      {delegateVisible && (
        <div>
          <Formik
            validateOnMount
            initialValues={{
              delegateMemberId: props.memberInfo.delegateMemberId || '',
              delegateMemberName: props.memberInfo.delegateMemberName || '',
              delegateMemberPositionName: props.memberInfo.delegateMemberPositionName || '',
              searchName: '',
              searchPhoneNumber: '',
            }}
            onSubmit={async (values, { resetForm }) => {
              const memberInfoChange: MemberInfoChange = {
                memberChangeType: MemberChangeType.DELEGATE_MEMBER_ID,
                currentValue: null,
                changeValue: values.delegateMemberId.trim(),
              };
              setLoading(true);
              const submitStatus = await memberInfoService.putMemberInfo(memberInfoChange);
              setLoading(false);
              if (submitStatus.successOrNot === 'Y') {
                setDelegateMemberId(values.delegateMemberId.trim());

                resetForm({
                  values: {
                    delegateMemberId: delegateMemberId || '',
                    delegateMemberName: delegateMemberName || '',
                    delegateMemberPositionName: delegateMemberPositionName || '',
                    searchName: '',
                    searchPhoneNumber: '',
                  },
                });
                setDelegateInputFormOpen(false);
                setToast(TOAST_ID_TYPE.SUCCESS, TOAST_CLASS_TYPE.DELEGATE);
              } else {
                setToast(TOAST_ID_TYPE.FAIL, TOAST_CLASS_TYPE.DELEGATE);
              }
            }}
          >
            {({
              values,
              errors,
              isSubmitting,
              handleChange,
              handleBlur,
              handleSubmit,
              resetForm,
              setFieldValue,
            }): any => (
              <Card>
                <Card.Header>
                  <Row>
                    <Col sm={10}>
                      <Row className="info-item-title">
                        {t('member.label.memberInfoForm.delegateMember.titleLabel')}
                      </Row>
                      <Row
                        className={delegateEditable ? 'info-item-desc' : 'info-item-desc disable-delegate'}
                        id="currentDelegate"
                        data-testid="currentDelegate"
                      >
                        {delegateMemberName} {delegateMemberPositionName}
                        {delegateMemberId && (
                          <Image
                            src={close24Icon}
                            data-testid="delete-cond"
                            className={'d-inline-block delete-ico'}
                            onClick={async () => {
                              const memberInfoChange: MemberInfoChange = {
                                memberChangeType: MemberChangeType.DELEGATE_MEMBER_ID,
                                currentValue: null,
                                changeValue: '',
                              };
                              setLoading(true);
                              const submitStatus = await memberInfoService.putMemberInfo(memberInfoChange);
                              setLoading(false);
                              if (submitStatus.successOrNot === 'Y') {
                                setDelegateMemberId('');
                                setDelegateMemberName(null);
                                setDelegateMemberPositionName(null);

                                resetForm({
                                  values: {
                                    delegateMemberId: '',
                                    delegateMemberName: '',
                                    delegateMemberPositionName: '',
                                    searchName: '',
                                    searchPhoneNumber: '',
                                  },
                                });
                                setDelegateInputFormOpen(false);
                                setToast(TOAST_ID_TYPE.SUCCESS, TOAST_CLASS_TYPE.DELEGATE);
                              } else {
                                setToast(TOAST_ID_TYPE.FAIL, TOAST_CLASS_TYPE.DELEGATE);
                              }
                            }}
                          />
                        )}
                      </Row>
                    </Col>
                    <Col sm={2}>
                      <Button
                        disabled={!delegateEditable}
                        onClick={() => {
                          setDelegateInputFormOpen(!delegateInputFormOpen);
                          setAllDelegateList(undefined);
                          resetForm({
                            values: {
                              delegateMemberId: delegateMemberId || '',
                              delegateMemberName: delegateMemberName || '',
                              delegateMemberPositionName: delegateMemberPositionName || '',
                              searchName: '',
                              searchPhoneNumber: '',
                            },
                          });
                        }}
                        variant="link"
                        className={'toggle-button' + (delegateMemberId === '' ? '' : ' verical-align-bottom')}
                        id="toggleDelegateNo"
                        data-testid="toggleDelegateNo"
                      >
                        {delegateInputFormOpen
                          ? t('member.label.memberInfoForm.common.formHideLabel')
                          : t('member.label.memberInfoForm.common.formOpenLabel')}
                      </Button>
                    </Col>
                  </Row>
                  {!delegateEditable && (
                    <Row className="delegate-info-message">
                      {t('member.label.memberInfoForm.delegateMember.notiForIntegrateApprovalYWorkplace')}
                    </Row>
                  )}
                </Card.Header>

                <Collapse in={delegateInputFormOpen} data-testid="delegateCollapse">
                  <Card.Body>
                    <Form onSubmit={handleSubmit}>
                      <Form.Group>
                        <InputGroup className="empKeyword">
                          <InputGroup.Prepend>
                            <Image
                              src={searchBlack24Icon}
                              id="searchVisitToIcon"
                              data-testid="searchVisitToIcon"
                              onClick={async e => {
                                /*eslint-disable*/
                                if (!values.searchName.trim() || !values.searchPhoneNumber) {
                                  return;
                                }
                                let delegateList: Delegate[] = [];

                                setLoading(true);

                                const searchCond: SearchUser = {
                                  name: values.searchName,
                                  mobile: values.searchPhoneNumber,
                                };

                                const response: any = await memberServiece.getSearchUsers([], searchCond);

                                setLoading(false);

                                if (response) {
                                  delegateList = response.filter((user: User, index: number) => {
                                    return (
                                      user.id !== props.memberInfo.memberId + '' &&
                                      user.type === UserTypeEnum.LGMEMBER &&
                                      user.companyCode === props.memberInfo.companyCode
                                    );
                                  });
                                }

                                if (delegateList?.length === 1) {
                                  setFieldValue('delegateMemberId', delegateList[0].id);
                                } else {
                                  setAllDelegateList(delegateList);
                                  setShowSearchResultList(true);
                                }
                                /*eslint-enable*/
                              }}
                              className="searchIcon delegateSearch"
                            />
                          </InputGroup.Prepend>
                          <Form.Control
                            type="text"
                            className={'delegateMemberName visible col-md-4'}
                            placeholder={t('reservation.label.ReservationBaseInfo.person.employeeName')}
                            id="searchName"
                            name="searchName"
                            data-testid="searchName"
                            onChange={handleChange}
                            value={values.searchName}
                            onKeyPress={async e => {
                              await onDelegateSearchPressed(e, values, setFieldValue);
                            }}
                            isInvalid={values.searchName === delegateMemberName}
                          />
                          <InputMask
                            mask="9999"
                            formatChars={{
                              '9': '[0-9]',
                            }}
                            maskChar={null}
                            value={values.searchPhoneNumber || ''}
                            onChange={handleChange}
                          >
                            {(inputProps: any) => (
                              <FormControl
                                className="delegateMemberPhoneNumber visible col-md-5"
                                id="searchPhoneNumber"
                                data-testid="searchPhoneNumber"
                                placeholder={t('reservation.label.ReservationBaseInfo.person.phone')}
                                aria-label="mobile"
                                value={values.searchPhoneNumber}
                                onKeyPress={async e => {
                                  await onDelegateSearchPressed(e, values, setFieldValue);
                                }}
                                autoComplete={'off'}
                              />
                            )}
                          </InputMask>
                          <div style={{ display: 'contents' }}>
                            {values.searchPhoneNumber !== '' && (
                              <Image
                                src={enter24Icon}
                                data-testid="enter-search-cond"
                                className={'d-inline-block enter-search-cond2 '}
                              />
                            )}
                          </div>
                          {showSearchResultList && (
                            <div className="dropDown">
                              {allDelegateList &&
                                allDelegateList.map((delegate, index) => (
                                  <Dropdown.Item
                                    key={index}
                                    eventKey={delegate.id}
                                    onSelect={(eventKey: any, event: Record<string, any>) => {
                                      const selected = allDelegateList.filter(delegate => delegate.id === eventKey)[0];
                                      setFieldValue('delegateMemberId', selected.id);
                                      setShowSearchResultList(false);
                                      setDelegateMemberName(selected.name);
                                      setDelegateMemberPositionName(selected.empType);
                                    }}
                                    id="searchDelegateResult"
                                    data-testid="searchDelegateResult"
                                    className="oneLine visitTo searchVisitToResult"
                                  >
                                    <Image src={dropDownPersonIcon} />
                                    <p className="d-inline-block">
                                      <strong>
                                        {delegate.name}&nbsp;{delegate.empType}&nbsp;/&nbsp;{delegate.emailAddress}
                                      </strong>
                                    </p>
                                  </Dropdown.Item>
                                ))}
                              {allDelegateList && allDelegateList.length === 0 && (
                                <Dropdown.Item
                                  id="searchDelegateNoResult"
                                  className="noResult"
                                  data-testid="searchDelegateNoResult"
                                >
                                  <Image src={noResultIcon} />
                                  <p className="d-inline-block">
                                    <strong>{t('common.message.info.search.nodata')}</strong>
                                  </p>
                                </Dropdown.Item>
                              )}
                            </div>
                          )}
                          <Form.Control.Feedback
                            id="delegateNoFeedback"
                            data-testid="delegateNoFeedback"
                            type="invalid"
                          >
                            {values.delegateMemberId === delegateMemberId &&
                              t('member.label.memberInfoForm.common.noChange')}
                          </Form.Control.Feedback>
                          <Button
                            id="delegateChangeButton"
                            name="delegateChangeButton"
                            data-testid="delegateChangeButton"
                            type="submit"
                            className="submit-button top-empty-space"
                            disabled={isSubmitting || values.delegateMemberId === delegateMemberId}
                          >
                            {t('member.label.memberInfoForm.delegateMember.buttonLabel')}
                          </Button>
                        </InputGroup>
                      </Form.Group>
                    </Form>
                  </Card.Body>
                </Collapse>
              </Card>
            )}
          </Formik>
          <Form.Row className="seperation-line" />
        </div>
      )}
      <Toast
        id={toastId}
        data-testid={toastId}
        onClose={() => setShowToast(false)}
        show={showToast}
        delay={3000}
        autohide
        className={toastClassName}
      >
        <Toast.Body>
          <span className="toast-contents">
            <i className="material-icons">{toastIcon}</i>
            <span id="toastMessage" className="toast-message">
              {toastMessage}
            </span>
          </span>
        </Toast.Body>
      </Toast>
    </div>
  );
};
export default EmployeeMemberInfoForm;
