import React, { useState, useEffect } from 'react';
import DOMPurify from 'dompurify';
import './WorkplaceFAQModal.scss';
import { Modal, Accordion, Card, Button, Row, Col, DropdownButton, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { FaqRequestModel, FaqList, FAQModifyMode } from '../model/Workplace';
import WorkplaceService from '../services/WorkplaceService';
import CommonSpinner from '../../../layouts/Spinner';
import { User } from '../model/User';
import { reservationDetailUtil } from '../utils/ReservationDetailUtil';
import DropdownItem from 'react-bootstrap/DropdownItem';

export interface WorkplaceFAQModalI {
  onHide: () => void;
  onModifyFAQ?: (target: FaqList | null, mode: FAQModifyMode) => void;
  sessionUser?: User | null;
}

export const WorkplaceFAQModal: React.FC<WorkplaceFAQModalI> = (props: WorkplaceFAQModalI) => {
  const { t } = useTranslation();
  const maxDisplayItemCount = 5;
  const [totalPageCount, setTotalPageCount] = useState(0);
  const [FAQList, setFAQList] = useState<Array<FaqList>>([]);
  const [currentPageIndex, setCurrentPageIndex] = useState(0);
  const [currentActiveItemIndex, setCurrentActiveItemIndex] = useState('0');
  const [keyword, setKeyword] = useState<string>('');
  const workplaceService = new WorkplaceService();

  const path = process.env.REACT_APP_S3_FILE_DOWNLOAD_URL || '';

  const filesizeFormatter = (bytes: number): string => {
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    if (bytes === 0) return '0 Byte';
    const i = Math.floor(Math.log(bytes) / Math.log(1024));
    return '(' + Math.round((bytes / Math.pow(1024, i)) * 100) / 100 + sizes[i] + ')';
  };
  const [isLoading, setLoading] = useState<boolean>(false);
  const [searchType, setSearchType] = useState<string>('title');
  const [searchItemIndex, setSearchItemIndex] = useState<number>(0);
  const searchItem: string[] = [
    t('reservation.label.SystemNoticeModal.searchTitle'),
    t('reservation.label.SystemNoticeModal.searchContent'),
  ];

  const handleModifyButtonClick = (e: any, modifyType: FAQModifyMode, item: FaqList | null) => {
    e.stopPropagation();
    e.preventDefault();

    if (props.onModifyFAQ) {
      props.onModifyFAQ(item, modifyType);
    }
  };

  const onSelectItem = (index: string) => {
    setSearchItemIndex(Number(index));
    Number(index) === 0 ? setSearchType('title') : setSearchType('content');
  };

  const getFAQListByKeyword = () => {
    getFAQList()
      .then(response => {
        if (response) {
          setFAQList(response.faqList);
          setTotalPageCount(response.totalPageCount);
          setCurrentActiveItemIndex('0');
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getFAQList = async () => {
    setLoading(true);
    const request: FaqRequestModel = {
      languageCode: localStorage.getItem('lang') ? (localStorage.getItem('lang') as string) : 'ko',
      startIndex: currentPageIndex * maxDisplayItemCount,
      pageSize: maxDisplayItemCount,
    };
    if (props.sessionUser && props.sessionUser.isSystemAdmin) {
      request.requestedId = props.sessionUser.id;
    }
    if (keyword && keyword != null) {
      request.keyword = keyword;
      request.searchType = searchType;
    }
    return await workplaceService.getFAQList(request);
  };

  /* eslint-disable */
  useEffect(() => {
    getFAQList()
      .then(response => {
        if (response) {
          setFAQList(response.faqList);
          setTotalPageCount(response.totalPageCount);
          setCurrentActiveItemIndex('0');
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, [currentPageIndex]);
  /* eslint-enable */

  return (
    <Modal
      show={true}
      centered={true}
      onHide={props.onHide}
      className="faq-modal"
      data-testid="faq-modal"
      id="FAQModal"
    >
      <Modal.Header closeButton>
        <div className="title" data-testid="faq-modal-header">
          {t('workplace.faqModal.title')}
        </div>
      </Modal.Header>
      <Modal.Body>
        <div className="faq-filter-area">
          <Row>
            <DropdownButton id="select-search" className="select-search" title={searchItem[searchItemIndex]}>
              {searchItem.map((key, index) => {
                return (
                  <DropdownItem
                    id="select-search-item"
                    className="select-search-item"
                    key={index}
                    eventKey={String(index)}
                    onSelect={onSelectItem}
                  >
                    {searchItem[index]}
                  </DropdownItem>
                );
              })}
            </DropdownButton>
            <Col>
              <Form.Control
                id="faqInput"
                name="faqInput"
                className="faq-input-control"
                type="text"
                value={keyword}
                onChange={e => setKeyword(e.target.value)}
              />
            </Col>
            <Button
              id="faqButton"
              name="faqButton"
              className="faq-search-button"
              type="submit"
              onClick={() => getFAQListByKeyword()}
            >
              <i id="searchIcon" data-testid="searchIcon" className="material-icons">
                search
              </i>
              <span>검색</span>
            </Button>
          </Row>
        </div>
        {!isLoading && FAQList !== undefined && FAQList.length && FAQList.length > 0 ? (
          <div>
            <Accordion activeKey={currentActiveItemIndex}>
              {FAQList.map((item, i) => {
                return (
                  <Card key={i}>
                    <Accordion.Toggle
                      as={Card.Header}
                      eventKey={i + ''}
                      onClick={() => {
                        if (currentActiveItemIndex === i + '') {
                          setCurrentActiveItemIndex('');
                        } else {
                          setCurrentActiveItemIndex(i + '');
                        }
                      }}
                    >
                      <Row>
                        <Col>
                          {item.faqTitle}
                          {props.onModifyFAQ && props.sessionUser && props.sessionUser.isSystemAdmin && (
                            <span className="button-for-admin">
                              <i
                                className="material-icons edit"
                                onClick={e => {
                                  handleModifyButtonClick(e, FAQModifyMode.EDIT, item);
                                }}
                              >
                                edit
                              </i>
                              <i
                                className={'material-icons eye' + (item.useYn === 'Y' ? ' filled' : ' gray')}
                                onClick={e => {
                                  handleModifyButtonClick(e, FAQModifyMode.VISIBLE, item);
                                }}
                              >
                                {item.useYn === 'Y' ? 'visibility' : 'visibility_off'}
                              </i>
                            </span>
                          )}
                          <i className="material-icons arrow">
                            {currentActiveItemIndex === i + '' ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}
                          </i>
                        </Col>
                      </Row>
                    </Accordion.Toggle>
                    <div className="line" />
                    <Accordion.Collapse eventKey={i + ''}>
                      <div>
                        <Card.Body>
                          {item.fileOriginalName !== null ? (
                            <Row>
                              <Col md="auto">
                                <i className="material-icons">get_app</i>
                              </Col>
                              <Col>
                                <a
                                  href={path + item.filePath + '/' + item.fileOriginalName}
                                  target="_blank"
                                  download={item.fileName}
                                >
                                  {t('workplace.faqModal.attatchedFile') +
                                    item.fileName +
                                    '.' +
                                    item.fileExtension +
                                    ' ' +
                                    filesizeFormatter(item.fileSize)}
                                </a>
                              </Col>
                            </Row>
                          ) : (
                            ''
                          )}

                          <Row>
                            <span
                              className="center"
                              dangerouslySetInnerHTML={{
                                __html: DOMPurify.sanitize(item.faqContent.replace(/(\n|\r\n)/g, '<br>')),
                              }}
                            ></span>
                          </Row>
                        </Card.Body>
                        <div className="line" />
                      </div>
                    </Accordion.Collapse>
                  </Card>
                );
              })}
            </Accordion>
            <p>
              <Button
                className="btn-arrow"
                disabled={currentPageIndex === 0}
                onClick={() => {
                  setCurrentPageIndex(Math.max(currentPageIndex - 1, 0));
                }}
              >
                <i className="material-icons">keyboard_arrow_left</i>
              </Button>
              <Button
                className="btn-arrow"
                disabled={currentPageIndex === totalPageCount - 1}
                onClick={() => {
                  setCurrentPageIndex(Math.min(currentPageIndex + 1, totalPageCount - 1));
                }}
              >
                <i className="material-icons">keyboard_arrow_right</i>
              </Button>
            </p>
          </div>
        ) : (
          !isLoading && <div className="no-faq">{t('workplace.faqModal.noContent')}</div>
        )}
        {isLoading && <CommonSpinner />}
        <Button className="btn-ok" onClick={props.onHide}>
          {t('workplace.faqModal.close')}
        </Button>
        {props.onModifyFAQ && props.sessionUser && props.sessionUser.isSystemAdmin && (
          <Button
            className="btn-create"
            variant="dark"
            onClick={e => {
              handleModifyButtonClick(e, FAQModifyMode.CREATE, null);
            }}
          >
            신규생성
          </Button>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default WorkplaceFAQModal;
