import React, { ReactElement, useState, useEffect } from 'react';
import { Modal, Button, Image, Form, InputGroup, FormControl, Badge } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import DOMPurify from 'dompurify';
import { Visitor } from '../model/ReservationTypes';
import { getMobileAsteriskMask } from '../utils/util';
import { ImportedGoodsButtonGroup } from './ImportedGoodsButtonGroup';
import { ImportedGoods } from '../model/ReservationTypes';
import OutsideClickHandler from 'react-outside-click-handler';
import VisitPeriodCalendar from './VisitPeriodCalendar';
import moment from 'moment';
import calendarIcon from '../../../icons/calendar_black-16px.svg';
import ImportedNotebookForm from './ImportedNotebookForm';
import ImportedGoodsForm from './ImportedGoodsForm';
import ImportedCNSGoodsForm from './ImportedCNSGoodsForm';
import { ImportedGoodsCodeEnum, MemberTypeCode, UserTypeEnum, DateFormat } from '../model/ReservationConstants';
import { FormikHelpers, FormikProps, Formik, FormikTouched, FormikErrors } from 'formik';
import { getVisitorDetailInfoSchema } from '../validation/VisitorDetailValidationSchema';
import { CommonCode } from '../model/Code';
import './VisitorDetailInfoModal.scss';
import CommonCodeService from '../services/CommonCodeService';
import ReservationService from '../services/ReservationService';
import updatePencil from '../../../icons/pencil.svg';
import InputMask from 'react-input-mask';
import { User } from '../model/User';
import { useHistory } from 'react-router-dom';
import { Workplace } from '../model/Workplace';
export interface VisitorDetailInfoModalProps {
  visitor: Visitor;
  onConfirm: (visitor: Visitor) => void;
  onClose: () => void;
  parkingAbleYesorno: string;
  portSealExceptYesorno: string;
  portSealExceptCodeList: CommonCode[];
  wifiAbleYesorno: string;
  macAddressYesorno: string;
  visitImportedGoodsYesorno: string;
  getCommonCode?: (groupCodeId: string) => CommonCode[];
  availableGoodsList: CommonCode[];
  maxVisitPeriod?: number;
  sessionUserInfo?: User;
  workplace: Workplace;
}

export const VisitorDetailInfoModal: React.FC<VisitorDetailInfoModalProps> = (
  props: VisitorDetailInfoModalProps
): ReactElement => {
  const { t } = useTranslation();
  const reservationService = new ReservationService();
  const initVisitorInfo: Visitor = {
    ...props.visitor,
    applyMemberCarNumber: props.visitor.applyMemberCarNumber ? props.visitor.applyMemberCarNumber : '',
  };
  const workplace: Workplace = props.workplace;
  const sessionUserInfo: User | undefined = props.sessionUserInfo;
  const isNomemberRequest: boolean =
    sessionUserInfo &&
    props.visitor &&
    sessionUserInfo.memberTypeCode === MemberTypeCode.NO_MEM &&
    sessionUserInfo.id === props.visitor.id
      ? true
      : false;
  const [showVisitPeriodCalendar, setShowVisitPeriodCalendar] = useState<boolean>(false);
  const [showClearCarNumberButton, setShowClearCarNumberButton] = useState<boolean>(
    props.visitor.applyMemberCarNumber ? true : false
  );
  const [showClearNameButton, setshowClearNameButton] = useState<boolean>(props.visitor.name ? true : false);
  const [showClearPostionButton, setShowClearPostionButton] = useState<boolean>(props.visitor.position ? true : false);
  const [showClearCompanyNameButton, setShowClearCompanyNameButton] = useState<boolean>(
    props.visitor.companyName ? true : false
  );
  const [showClearMobileButton, setShowClearMobileButton] = useState<boolean>(
    props.visitor.applyMemberMobilePhone ? true : false
  );
  const [isEditable, setIsEditable] = useState<boolean>(false);
  const [visitToPhoneMask, setVisitToPhoneMask] = useState<string>('999-9999-9999');
  const [goodsLastIndex, setGoodsLastIndex] = useState<number>(
    Math.max(...props.visitor.importedGoodsList.map(good => good.id), 0)
  );

  const importedGoodsPurposeCodeList =
    props.getCommonCode && props.getCommonCode(CommonCodeService.IMPORTED_GOODS_PURPOSE);
  const history = useHistory();
  const handleVisitPeriodCalendar = (event: any) => {
    event.stopPropagation();
    event.preventDefault();
    if (workplace && workplace.registerVisitorEachPeriodYesorno === 'Y') {
      if (showVisitPeriodCalendar) {
        history.goBack();
      } else {
        if (!history.location.hash.includes('#visitorCalendar')) {
          history.push(history.location.hash + '#visitorCalendar');
        }
      }
      setShowVisitPeriodCalendar(!showVisitPeriodCalendar);
    }
  };
  const closeVisitPeriodCalendar = (event: any) => {
    event.stopPropagation();
    event.preventDefault();
    if (history.location.hash.includes('#visitorCalendar')) {
      history.goBack();
    }
    setShowVisitPeriodCalendar(false);
  };

  const handleSubmit = (visitor: Visitor, actions: FormikHelpers<Visitor>): void => {
    if (!visitor.importedGoodsList) {
      visitor.importedGoodsList = [];
    }

    //입력한 차량번호가 있으면 해당 사업장의 방문 제한 초과 여부 조회
    let maxParkingCountOvered = false;
    if (visitor.applyMemberCarNumber && workplace.monthlyParkingMaxCount && visitor.toVisitPeriod) {
      const fetchData = async () => {
        return reservationService.getParkingCountByCarNumber(
          moment(visitor.toVisitPeriod || '').format(DateFormat.DATETIME_IF),
          visitor.applyMemberCarNumber || '',
          workplace.workplaceId || ''
        );
      };

      fetchData()
        .then(response => {
          if (
            response.count &&
            workplace.monthlyParkingMaxCount &&
            response.count >= workplace.monthlyParkingMaxCount
          ) {
            actions.setFieldError(
              'applyMemberCarNumber',
              t('reservation.label.VisitorDetailInfoModal.exception.overMonthlyMaxCount', {
                maxCount: workplace.monthlyParkingMaxCount || '',
              })
            );

            maxParkingCountOvered = true;
          }
        })
        .finally(() => {
          if (!maxParkingCountOvered) {
            props.onConfirm(visitor);
          }
        });
    } else {
      props.onConfirm(visitor);
    }
  };

  const editNoneMemberInfo = () => {
    setIsEditable(!isEditable);
  };

  return (
    <Modal
      id="visitorDetailInfoModal"
      data-testid="visitorDetailInfoModal"
      show={true}
      className="visitorDetailInfoModal"
      centered
      enforceFocus={false}
      onHide={props.onClose}
    >
      <Formik
        data-testid="ReseravtionContainerFormik"
        enableReinitialize={true}
        initialValues={initVisitorInfo}
        validationSchema={getVisitorDetailInfoSchema(props.macAddressYesorno, isNomemberRequest)}
        onSubmit={handleSubmit}
      >
        {(formProps: FormikProps<Visitor>) => {
          const visitor: Visitor = formProps.values;
          const fromVisitPeriod = visitor.fromVisitPeriod ? moment(visitor.fromVisitPeriod) : null;
          const toVisitPeriod = visitor.toVisitPeriod ? moment(visitor.toVisitPeriod) : null;
          const date =
            fromVisitPeriod && toVisitPeriod
              ? fromVisitPeriod.format() === toVisitPeriod.format()
                ? fromVisitPeriod?.format(DateFormat.DATE_DISP)
                : fromVisitPeriod?.format(DateFormat.DATE_DISP) + ' ~ ' + toVisitPeriod?.format(DateFormat.DATE_DISP)
              : '';
          const onPeriodChange = (fromVisitPeriod: moment.Moment, toVisitPeriod: moment.Moment): void => {
            formProps.setFieldValue('fromVisitPeriod', fromVisitPeriod.format(DateFormat.DATE_IF));
            formProps.setFieldValue('toVisitPeriod', toVisitPeriod.format(DateFormat.DATE_IF));
            setShowVisitPeriodCalendar(false);
          };
          const onApplyMemberCarNumberChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
            const carNumber = event.target.value;
            if (carNumber) {
              setShowClearCarNumberButton(true);
            } else {
              setShowClearCarNumberButton(false);
            }

            formProps.setFieldValue('applyMemberCarNumber', carNumber);
          };

          const onClearCarNumber = (): void => {
            formProps.setFieldValue('applyMemberCarNumber', '');
            setShowClearCarNumberButton(false);
          };

          const onGoodsClose = (id: number): void => {
            formProps.setFieldValue(
              'importedGoodsList',
              visitor.importedGoodsList?.filter(goods => goods.id !== id)
            );
          };

          const checkedGoodsCodeList = visitor.importedGoodsList
            ? visitor.importedGoodsList.map((importedGoods: ImportedGoods, i) => {
                return importedGoods.importedGoodsCode;
              })
            : [];

          const onButtonGroupChange = (checkedList: string[]): void => {
            const importedGoodsList = visitor.importedGoodsList ? [...visitor.importedGoodsList] : [];
            const newGoodsCode = checkedList.pop();
            const newGoods = props.availableGoodsList.find(goods => goods.codeId === newGoodsCode);
            if (newGoods) {
              importedGoodsList.push({
                id: goodsLastIndex + 1,
                importedGoodsCode: newGoods.codeId,
                importedGoodsName: newGoods.codeName,
                importedGoodsPurpose: '',
                importedGoodsDescription: '',
                importedGoodsCounts: '1',
              });
              setGoodsLastIndex(goodsLastIndex + 1);
            }
            formProps.setFieldValue('importedGoodsList', importedGoodsList);
          };

          const onImportedGoodsChange = (importedGoods: ImportedGoods): void => {
            const importedGoodsList = [...visitor.importedGoodsList];
            importedGoodsList[importedGoodsList.findIndex(goods => goods.id === importedGoods.id)] = importedGoods;
            formProps.setFieldValue('importedGoodsList', importedGoodsList);
          };

          const handleChangeNoneMemName = (event: React.ChangeEvent<HTMLInputElement>) => {
            const name = event.target.value.trim();
            formProps.setFieldValue('name', name);
            if (name) {
              setshowClearNameButton(true);
            } else {
              setshowClearNameButton(false);
            }
          };

          const handleChangeNoneMemPostion = (event: React.ChangeEvent<HTMLInputElement>) => {
            const position = event.target.value;
            formProps.setFieldValue('position', position);
            if (position) {
              setShowClearPostionButton(true);
            } else {
              setShowClearPostionButton(false);
            }
          };

          const handleChangeNoneMemCompany = (event: React.ChangeEvent<HTMLInputElement>) => {
            const companyName = event.target.value;
            formProps.setFieldValue('companyName', companyName);
            if (companyName) {
              setShowClearCompanyNameButton(true);
            } else {
              setShowClearCompanyNameButton(false);
            }
          };

          const handleChangeNoneMemPhone = (event: React.ChangeEvent<HTMLInputElement>) => {
            const applyMemberMobilePhone = event.target.value.trim();
            formProps.setFieldValue('applyMemberMobilePhone', applyMemberMobilePhone.replace(/-/gi, ''));
            if (applyMemberMobilePhone) {
              setShowClearMobileButton(true);
            } else {
              setShowClearMobileButton(false);
            }
          };

          const onClearNoneMemName = (): void => {
            formProps.setFieldValue('name', '');
            setshowClearNameButton(false);
          };
          const onClearNoneMemPosition = (): void => {
            formProps.setFieldValue('position', '');
            setShowClearPostionButton(false);
          };
          const onClearNoneMemCompany = (): void => {
            formProps.setFieldValue('companyName', '');
            setShowClearCompanyNameButton(false);
          };
          const onClearNoneMemMobile = (): void => {
            formProps.setFieldValue('applyMemberMobilePhone', '');
            setShowClearMobileButton(false);
          };

          const handleUserMobileMaskChange = (newState, oldState, userInput) => {
            const newValue: string = newState.value;
            const oldValue: string = oldState.value;
            const regexN = /[^0-9]/;

            if (newValue.length > 3) {
              if (!newValue.startsWith('010')) setVisitToPhoneMask('999-999-9999');
              else setVisitToPhoneMask('999-9999-9999');
            }

            if (
              (userInput === null || oldValue.length < visitToPhoneMask.length) &&
              (userInput !== ' ' || regexN.test(userInput))
            ) {
              return newState;
            } else {
              return oldState;
            }
          };

          const validationMessageDiv = (
            errors: FormikErrors<Visitor>,
            touched: FormikTouched<Visitor>
          ): JSX.Element => {
            let result: JSX.Element = <></>;

            if (errors.importedGoodsList && touched.importedGoodsList) {
              if (
                errors.importedGoodsList.length > 0 &&
                errors.importedGoodsList[0] &&
                errors.importedGoodsList[0]['importedGoodsWifiMacAddress'] === 'macAddress'
              ) {
                result = (
                  <div
                    id="importGoodsValidationMessage"
                    data-testid="importGoodsValidationMessage"
                    className={'text-danger validationMessage'}
                  >
                    <span>{t('reservation.label.VisitorDetailInfoModal.message.macAddress')}</span>
                  </div>
                );
              } else {
                result = (
                  <div
                    id="importGoodsValidationMessage"
                    data-testid="importGoodsValidationMessage"
                    className={'text-danger validationMessage'}
                  >
                    <span>{t('reservation.label.VisitorDetailInfoModal.message.required')}</span>
                  </div>
                );
              }
            }

            return result;
          };

          return (
            <Form onSubmit={formProps.handleSubmit}>
              <Modal.Header closeButton>
                {isEditable ? (
                  <Modal.Title>
                    <div className="editInfo">
                      {visitor.badgeType && <Badge className={visitor.badgeType}>{visitor.badgeName}</Badge>}
                      <InputGroup>
                        <Form.Control
                          className="editName"
                          type="text"
                          value={visitor.name}
                          placeholder={t('reservation.label.VisitorDetailInfoModal.placeholder.name')}
                          onChange={handleChangeNoneMemName}
                        />
                        <InputGroup.Append>
                          <InputGroup.Text>
                            {
                              <i
                                className={showClearNameButton ? 'material-icons' : 'material-icons invisable'}
                                onClick={onClearNoneMemName}
                              >
                                close
                              </i>
                            }
                          </InputGroup.Text>
                        </InputGroup.Append>
                        <Form.Control
                          className="editName"
                          type="text"
                          value={visitor.position}
                          placeholder={t('reservation.label.VisitorDetailInfoModal.placeholder.position')}
                          onChange={handleChangeNoneMemPostion}
                        />
                        <InputGroup.Append>
                          <InputGroup.Text>
                            {
                              <i
                                className={showClearPostionButton ? 'material-icons' : 'material-icons invisable'}
                                onClick={onClearNoneMemPosition}
                              >
                                close
                              </i>
                            }
                          </InputGroup.Text>
                        </InputGroup.Append>
                      </InputGroup>
                    </div>
                    <div className="editInfo">
                      <InputGroup>
                        <Form.Control
                          className="editCompany"
                          type="text"
                          value={visitor.companyName}
                          placeholder={t('reservation.label.VisitorDetailInfoModal.placeholder.company')}
                          onChange={handleChangeNoneMemCompany}
                        />
                        <InputGroup.Append>
                          <InputGroup.Text>
                            {
                              <i
                                className={showClearCompanyNameButton ? 'material-icons' : 'material-icons invisable'}
                                onClick={onClearNoneMemCompany}
                              >
                                close
                              </i>
                            }
                          </InputGroup.Text>
                        </InputGroup.Append>
                        <InputMask
                          mask={visitToPhoneMask}
                          formatChars={{
                            '9': '[0-9]',
                          }}
                          maskChar={null}
                          value={visitor.applyMemberMobilePhone || ''}
                          beforeMaskedValueChange={handleUserMobileMaskChange}
                          onChange={handleChangeNoneMemPhone}
                        >
                          {(inputProps: any) => {
                            return (
                              <Form.Control
                                className="editPhone"
                                type="text"
                                value={inputProps.applyMemberMobilePhone}
                                placeholder={t('reservation.label.VisitorDetailInfoModal.placeholder.mobile')}
                              />
                            );
                          }}
                        </InputMask>{' '}
                        <InputGroup.Append>
                          <InputGroup.Text>
                            {
                              <i
                                className={showClearMobileButton ? 'material-icons' : 'material-icons invisable'}
                                onClick={onClearNoneMemMobile}
                              >
                                close
                              </i>
                            }
                          </InputGroup.Text>
                        </InputGroup.Append>
                      </InputGroup>
                    </div>
                    {((formProps.errors.name && formProps.errors.name === 'required') ||
                      (formProps.errors.applyMemberMobilePhone &&
                        formProps.errors.applyMemberMobilePhone === 'required')) && (
                      <p className="text-danger">
                        {t('reservation.label.VisitorDetailInfoModal.exception.missingRequiredField')}
                      </p>
                    )}
                    {!formProps.errors.name &&
                      formProps.errors.applyMemberMobilePhone &&
                      formProps.errors.applyMemberMobilePhone !== 'required' && (
                        <p className="text-danger">
                          {t('reservation.label.VisitorDetailInfoModal.exception.mobileFormat')}
                        </p>
                      )}
                  </Modal.Title>
                ) : (
                  <Modal.Title>
                    <p className="showInfo">
                      {visitor.badgeType && <Badge className={visitor.badgeType}>{visitor.badgeName}</Badge>}
                      <strong>
                        {visitor.name} {visitor.position}
                      </strong>
                      {visitor.type === UserTypeEnum.NONEMEMBER && (
                        <Image className="editButton" src={updatePencil} onClick={editNoneMemberInfo}></Image>
                      )}
                    </p>
                    <p className="showInfo">
                      {visitor.companyName ? visitor.companyName : ''} {visitor.dept ? visitor.dept : ''}
                      {!visitor.companyName && !visitor.dept ? '' : ' / '}
                      {getMobileAsteriskMask(visitor.applyMemberMobilePhone)}
                    </p>
                  </Modal.Title>
                )}
              </Modal.Header>
              <Modal.Body>
                {!isNomemberRequest && (
                  <Form.Row>
                    <Form.Group>
                      <Form.Label>{t('reservation.label.VisitorDetailInfoModal.visitPeriod.title')}</Form.Label>

                      <InputGroup
                        id="calendarInput"
                        data-testid="calendarInput"
                        onClick={handleVisitPeriodCalendar}
                        className={'visitPeriodInput'}
                      >
                        <InputGroup.Prepend>
                          <Image src={calendarIcon} className="calendarIcon" />
                        </InputGroup.Prepend>
                        <FormControl
                          id="visitPeriod"
                          data-testid="visitPeriod"
                          aria-label="calendar"
                          readOnly
                          plaintext
                          value={date}
                        />
                      </InputGroup>
                    </Form.Group>
                    <OutsideClickHandler onOutsideClick={closeVisitPeriodCalendar}>
                      {showVisitPeriodCalendar && (
                        <VisitPeriodCalendar
                          className="outline-secondary"
                          show={showVisitPeriodCalendar}
                          fromVisitPeriod={fromVisitPeriod}
                          toVisitPeriod={toVisitPeriod}
                          onPeriodChange={onPeriodChange}
                          maxVisitPeriod={props.maxVisitPeriod}
                          isCalendarAwalysOpen={true}
                          setShowCalendar={setShowVisitPeriodCalendar}
                          isVisitor={true}
                        ></VisitPeriodCalendar>
                      )}
                    </OutsideClickHandler>
                  </Form.Row>
                )}
                {props.parkingAbleYesorno === 'Y' && (
                  <Form.Row>
                    <Form.Group>
                      <Form.Label>
                        {t('reservation.label.VisitorDetailInfoModal.applyMemberCarNumber.title')}
                      </Form.Label>
                      <InputGroup>
                        <Form.Control
                          //disabled={visitor.fromVisitPeriod !== visitor.toVisitPeriod}
                          id="applyMemberCarNumber"
                          name="applyMemberCarNumber"
                          data-testid="applyMemberCarNumber"
                          className="formik-field"
                          type="text"
                          placeholder={t('reservation.label.VisitorDetailInfoModal.applyMemberCarNumber.placeholder')}
                          value={visitor.applyMemberCarNumber}
                          onChange={onApplyMemberCarNumberChange}
                          maxLength={10}
                        />

                        <InputGroup.Append>
                          <InputGroup.Text>
                            {showClearCarNumberButton && (
                              <i
                                className="material-icons"
                                id="clearCarNumberIcon"
                                data-testid="clearCarNumberIcon"
                                onClick={onClearCarNumber}
                              >
                                close
                              </i>
                            )}
                          </InputGroup.Text>
                        </InputGroup.Append>
                      </InputGroup>
                      {/* {visitor.fromVisitPeriod !== visitor.toVisitPeriod && (
                        <div
                          id="parkingAvailableOnlyForOneDayMessage"
                          data-testid="parkingAvailableOnlyForOneDayMessage"
                          className={'text-danger validationMessage'}
                        >
                          <span>
                            {t('reservation.label.VisitorDetailInfoModal.exception.parkingAvailableOnlyForOneDay')}
                          </span>
                        </div>
                      )} */}

                      {formProps.errors.applyMemberCarNumber && formProps.touched.applyMemberCarNumber && (
                        <div
                          id="importGoodsValidationMessage"
                          data-testid="importGoodsValidationMessage"
                          className={'text-danger validationMessage'}
                        >
                          <span>
                            {formProps.errors.applyMemberCarNumber === 'regExpCarNumber'
                              ? t('reservation.label.VisitorDetailInfoModal.exception.carNumFormat')
                              : formProps.errors.applyMemberCarNumber}
                          </span>
                        </div>
                      )}
                      <span
                        dangerouslySetInnerHTML={{
                          __html: DOMPurify.sanitize(
                            t('reservation.label.VisitorDetailInfoModal.applyMemberCarNumber.guide')
                          ),
                        }}
                      ></span>
                    </Form.Group>
                  </Form.Row>
                )}
                {props.visitImportedGoodsYesorno === 'Y' &&
                  props.availableGoodsList &&
                  props.availableGoodsList.length > 0 && (
                    <Form.Row>
                      <Form.Group>
                        <Form.Label>{t('reservation.label.VisitorDetailInfoModal.importedGoods.title')}</Form.Label>
                        {visitor.importedGoodsList &&
                          visitor.importedGoodsList.map((goods: ImportedGoods, i: number) => {
                            if (!goods.importedGoodsName) {
                              const goodsCode = props.availableGoodsList.find(
                                code => code.codeId === goods.importedGoodsCode
                              );
                              if (goodsCode) {
                                goods.importedGoodsName = goodsCode.codeName;
                              }
                            }
                            if (goods.importedGoodsCode === ImportedGoodsCodeEnum.NOTEBOOK) {
                              return (
                                <ImportedNotebookForm
                                  importedGoods={goods}
                                  portSealExceptYesorno={props.portSealExceptYesorno}
                                  wifiAbleYesorno={props.wifiAbleYesorno}
                                  macAddressYesorno={props.macAddressYesorno}
                                  onClose={onGoodsClose}
                                  key={goods.id}
                                  onImportedGoodsChange={onImportedGoodsChange}
                                  importedGoodsPurposeCodeList={importedGoodsPurposeCodeList}
                                  portSealExceptCodeList={props.portSealExceptCodeList}
                                  errors={
                                    formProps.errors.importedGoodsList ? formProps.errors.importedGoodsList[i] : null
                                  }
                                  touched={
                                    formProps.touched.importedGoodsList ? formProps.touched.importedGoodsList[i] : null
                                  }
                                  notebookSerialNo={
                                    sessionUserInfo && sessionUserInfo.id === visitor.id
                                      ? sessionUserInfo?.notebookSerialNo
                                      : ''
                                  }
                                ></ImportedNotebookForm>
                              );
                            } else if (
                              goods.importedGoodsCode === ImportedGoodsCodeEnum.SERVER ||
                              goods.importedGoodsCode === ImportedGoodsCodeEnum.STORAGE ||
                              goods.importedGoodsCode === ImportedGoodsCodeEnum.NETWORK ||
                              goods.importedGoodsCode === ImportedGoodsCodeEnum.PART
                            ) {
                              return (
                                <ImportedCNSGoodsForm
                                  importedGoods={goods}
                                  portSealExceptYesorno={props.portSealExceptYesorno}
                                  wifiAbleYesorno={props.wifiAbleYesorno}
                                  macAddressYesorno={props.macAddressYesorno}
                                  onClose={onGoodsClose}
                                  key={goods.id}
                                  onImportedGoodsChange={onImportedGoodsChange}
                                  importedGoodsPurposeCodeList={importedGoodsPurposeCodeList}
                                  portSealExceptCodeList={props.portSealExceptCodeList}
                                  errors={
                                    formProps.errors.importedGoodsList ? formProps.errors.importedGoodsList[i] : null
                                  }
                                  touched={
                                    formProps.touched.importedGoodsList ? formProps.touched.importedGoodsList[i] : null
                                  }
                                  notebookSerialNo={
                                    sessionUserInfo && sessionUserInfo.id === visitor.id
                                      ? sessionUserInfo?.notebookSerialNo
                                      : ''
                                  }
                                ></ImportedCNSGoodsForm>
                              );
                            } else {
                              return (
                                <ImportedGoodsForm
                                  importedGoods={goods}
                                  onClose={onGoodsClose}
                                  key={goods.id}
                                  onImportedGoodsChange={onImportedGoodsChange}
                                  importedGoodsPurposeCodeList={importedGoodsPurposeCodeList}
                                  errors={
                                    formProps.errors.importedGoodsList ? formProps.errors.importedGoodsList[i] : null
                                  }
                                  touched={
                                    formProps.touched.importedGoodsList ? formProps.touched.importedGoodsList[i] : null
                                  }
                                ></ImportedGoodsForm>
                              );
                            }
                          })}
                        {props.visitImportedGoodsYesorno === 'Y' &&
                          props.availableGoodsList &&
                          props.availableGoodsList.length > 0 && (
                            <ImportedGoodsButtonGroup
                              checkedGoodsCodeList={checkedGoodsCodeList}
                              onButtonGroupChange={onButtonGroupChange}
                              availableGoodsList={props.availableGoodsList}
                              duplicateImportedGoods={workplace.duplicateImportedGoodsYesorno}
                            ></ImportedGoodsButtonGroup>
                          )}

                        {formProps.errors.importedGoodsList &&
                          formProps.touched.importedGoodsList &&
                          validationMessageDiv(formProps.errors, formProps.touched)}
                      </Form.Group>
                    </Form.Row>
                  )}
              </Modal.Body>
              <Modal.Footer>
                <Button
                  id="visitorDetailInfoConfirmButton"
                  data-testid="visitorDetailInfoConfirmButton"
                  variant="primary"
                  onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                    event.preventDefault();
                    formProps.handleSubmit();
                  }}
                >
                  {t('reservation.label.VisitorDetailInfoModal.button.confirm')}
                </Button>
              </Modal.Footer>
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
};

export default VisitorDetailInfoModal;
