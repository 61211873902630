import React, { useState, ReactElement, useContext } from 'react';
import { Form, Modal, Container, Button } from 'react-bootstrap';
import { Formik, FormikProps, FormikHelpers, FormikErrors } from 'formik';
import { useTranslation } from 'react-i18next';
import DOMPurify from 'dompurify';
import MemberRegistrationService from '../../services/MemberRegistrationService';
import SessionService from '../../../../service/SessionService';
import { useHistory } from 'react-router-dom';
import { AuthContext } from '../../../../App';
import { ActionType } from '../../../../reducer/actions';
import './DeleteMemberAccountModal.scss';
import * as yup from 'yup';

export interface DeleteMemberAccountModalProps {
  show: boolean;
  onHide: () => void;
  setToastMessage: (msg: string) => void;
}

export interface DeleteMemberAccount {
  password: string;
  termAgreed: boolean;
}

const DeleteMemberAccountModal: React.FC<DeleteMemberAccountModalProps> = ({
  show,
  onHide,
  setToastMessage,
}: DeleteMemberAccountModalProps): ReactElement => {
  const [passwordInput, setPasswordInput] = useState<string>('');
  const [errorExists, setErrorExists] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<string>('');
  const [termAgreed, setTermAgreed] = useState<boolean>(false);
  const initDeleteMemberAccount = {
    password: '',
    termAgreed: false,
  };
  const history = useHistory();
  const { t } = useTranslation();
  const { state, dispatch } = useContext(AuthContext);

  const memberRegistrationService = new MemberRegistrationService();

  const sessionService = new SessionService();

  const schema = yup.object({
    password: yup.string().required(''),
    termAgreed: yup.boolean(),
  });

  const modalBody = (props: FormikProps<DeleteMemberAccount>): JSX.Element => {
    const handleClose = () => {
      props.resetForm();
      setPasswordInput('');
      setTermAgreed(false);
      setErrorExists(false);
      onHide();
    };

    const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
      props.setFieldValue(event.target.id, event.target.value);
    };

    return (
      <Modal show={show} onHide={handleClose} centered={true} className="delete-member-account-modal">
        <Modal.Header closeButton>
          <Modal.Title>
            <Container>
              <div className="header bold">{t('member.label.memberInfoForm.deleteMemberAccount.titleLabel')}</div>
              <br />
              <div className="header light">{t('member.label.memberInfoForm.deleteMemberAccount.subtitleLabel')}</div>
              <br />
              <div className="header light">{t('member.label.memberInfoForm.deleteMemberAccount.subtitleLabel2')}</div>
            </Container>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="modal-body">
          <Form onSubmit={props.handleSubmit}>
            <div>
              <Form.Row className="top-empty-space">
                <Form.Group>
                  <Form.Control
                    id="password"
                    name="password"
                    data-testid="password"
                    required
                    type="password"
                    placeholder={t('member.label.memberInfoForm.deleteMemberAccount.placeholder')}
                    isInvalid={props.errors.password ? true : false}
                    onChange={handlePasswordChange}
                  />
                  <Form.Control.Feedback id="passwordFeedback" data-testid="passwordFeedback" type="invalid">
                    {props.errors.password}
                  </Form.Control.Feedback>
                </Form.Group>
              </Form.Row>
              <Form.Row>
                <Form.Check
                  custom
                  id="termAgreed"
                  name="termAgreed"
                  data-testid="termAgreed"
                  type="checkbox"
                  checked={termAgreed}
                  label={t('member.label.memberInfoForm.deleteMemberAccount.termAgree')}
                  onChange={() => {
                    setTermAgreed(!termAgreed);
                  }}
                ></Form.Check>
                <p
                  id="errorFeedback"
                  data-testid="errorFeedback"
                  dangerouslySetInnerHTML={errorExists ? { __html: DOMPurify.sanitize(errorMsg) } : { __html: '' }}
                ></p>
              </Form.Row>
              <Form.Row>
                <Button
                  id="deleteMemberAccountButton"
                  name="deleteMemberAccountButton"
                  data-testid="deleteMemberAccountButton"
                  type="submit"
                  className="submit-button"
                  disabled={props.isSubmitting || !termAgreed || props.values.password.length === 0}
                >
                  {t('member.label.memberInfoForm.deleteMemberAccount.deleteMemberAccountButton')}
                </Button>
              </Form.Row>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    );
  };

  const handleSubmit = async (
    values: DeleteMemberAccount,
    actions: FormikHelpers<DeleteMemberAccount>
  ): Promise<void> => {
    const deleteMemberAccountRequest = {
      emailAddress: '',
      memberName: '',
      phoneNumber: '',
      certificationId: '',
      password: values.password,
      memberTypeCode: '',
      memberStateCode: '',
      certificationSendAddress: '',
    };
    dispatch({ type: ActionType.LOADING, loading: { isLoading: true } });
    const response = await memberRegistrationService.deleteMember(deleteMemberAccountRequest);
    dispatch({ type: ActionType.LOADING, loading: { isLoading: false } });

    if (response && response.statusCode === 'SUCCESS' && response.successOrNot === 'Y') {
      sessionService.deleteSessionInfo();
      actions.resetForm();
      onHide();
      const toastMessage = {
        toastMessage: t('member.label.memberInfoForm.deleteMemberAccount.toast'),
      };
      dispatch({ type: ActionType.SHOW_TOAST, toastMessage: toastMessage });
      history.push('/login');
    } else if (response.statusCode === 'WRONG.PASSWORD') {
      actions.setFieldError(
        'password',
        t('member.label.memberInfoForm.deleteMemberAccount.backEnd.' + response.statusCode)
      );
    } else if (
      ['VISIT.RESERVATION.IN.PROGRESS', 'INVALID.USER', 'INVALID.MEMBER.TYPE', 'VISIT.INTERFACE.ERROR'].includes(
        response.statusCode
      )
    ) {
      setErrorExists(true);
      setErrorMsg(t('member.label.memberInfoForm.deleteMemberAccount.backEnd.' + response.statusCode));
    } else {
      alert(t('member.message.backEnd.UE'));
    }
  };

  return (
    <div>
      <Formik
        data-testid="DeleteMemberAccountModalFormik"
        enableReinitialize={true}
        initialValues={initDeleteMemberAccount}
        render={modalBody}
        onSubmit={handleSubmit}
        validationSchema={schema}
        isInitialValid={schema.isValidSync(initDeleteMemberAccount)}
      />
    </div>
  );
};

export default DeleteMemberAccountModal;
