import React, { ReactElement, useState, useContext, useEffect } from 'react';
import {
  CardDeck,
  Card,
  InputGroup,
  FormControl,
  Image,
  Dropdown,
  Button,
  Modal,
  OverlayTrigger,
  Tooltip,
} from 'react-bootstrap';
import DOMPurify from 'dompurify';
import calendarIcon from '../../../icons/calendar.svg';
import searchBlack24Icon from '../../../icons/search_black-24px.svg';
import searchWhite16Icon from '../../../icons/search_white-16px.svg';
import factoryIcon from '../../../icons/factory.svg';
import officeIcon from '../../../icons/office.svg';
import placeIcon from '../../../icons/place-white-18dp.svg';
import personIcon from '../../../icons/person-white-18dp.svg';
import scheduleIcon from '../../../icons/schedule-white-18dp.svg';

import InputMask from 'react-input-mask';
import dropDownPersonIcon from '../../../icons/dropdown_person.svg';
import noResultIcon from '../../../icons/no_result.svg';
import vPortalLogo from '../../../icons/vportal_logo_color.png';
import singlexLogo from '../../../icons/logo_vrp_kr.png';
import singlexLogoEng from '../../../icons/logo_vrp_en.png';
import questionMarkIcon from '../../../icons/question-20px.svg';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { VisitTo, VisitHistory, WorkplaceVisitInfo, Visitor } from '../model/ReservationTypes';
import OutsideClickHandler from 'react-outside-click-handler';
import { Workplace } from '../model/Workplace';
import { User } from '../model/User';
import './ReservationBaseInfo.scss';
import VisitPeriodCalendar from './VisitPeriodCalendar';
import WorkplaceService from '../services/WorkplaceService';
import { SearchVisitToInfo } from '../model/Member';
import MemberService from '../services/MemberService';
import ReservationService from '../services/ReservationService';
import ReservationConstants, { MemberTypeCode, DateFormat } from '../model/ReservationConstants';
import AlertModal from './AlertModal';
import MemberRegistrationConstants from '../../member/model/MemberRegistrationConstants';
import { AuthContext } from '../../../App';
import { ActionType } from '../../../reducer/actions';
import WorkplaceAlertModal from './WorkplaceAlertModal';
import { useHistory } from 'react-router-dom';
import enter24Icon from '../../../icons/subdirectory_arrow_left-24px.svg';
import { CommonCode } from '../model/Code';
/**
 * https://www.taniarascia.com/crud-app-in-react-with-hooks/
 */
export interface ReservationBaseInfoProps {
  formProps: any;
  fromVisitPeriod: string | null;
  toVisitPeriod: string | null;
  workplace?: Workplace;
  sessionUser: User | undefined;
  visitTo?: VisitTo;
  visitHistory?: VisitHistory[];
  setFieldError?: Function;
  errors?: any;

  values?: any;
  setFieldValue?: Function;
  handleShowConfirmModal?: Function;
  checkIsSubmited: Function;
  setValues?: Function;
  setWorkplaceVisitInfo: Function;
  focusWorkplace?: boolean;
  commonCodeMap: Map<string, CommonCode[]> | undefined;
}

export const ReservationBaseInfo: React.FC<ReservationBaseInfoProps> = (
  props: ReservationBaseInfoProps
): ReactElement => {
  const { t, i18n } = useTranslation();
  const { dispatch } = useContext(AuthContext);

  const workplaceService = new WorkplaceService();
  const memberServiece = new MemberService();
  const reservationService = new ReservationService();
  const userInfo = props.sessionUser;

  const fromVisitPeriod = props.fromVisitPeriod ? moment(props.fromVisitPeriod) : null;
  const toVisitPeriod = props.toVisitPeriod ? moment(props.toVisitPeriod) : null;

  const [showSearchWorkplace, setShowSearchWorkplace] = useState<boolean>(false);
  const [showSearchVisitTo, setShowSearchVisitTo] = useState<boolean>(false);
  const [searchByEmail, setSearchByEmail] = useState<boolean>(false);

  const [workplaceList, setWorkplaceList] = useState<Workplace[] | null>(null);
  const [showSearchWorkplaceResult, setShowSearchWorkplaceResult] = useState<boolean>(false);
  const [showSearchVisitToResult, setShowSearchVisitToResult] = useState<boolean>(false);
  const [visitToHistoryByWorkplace, setVisitToHistoryByWorkplace] = useState<VisitHistory[] | undefined>();
  const [workplaceKeyword, setWorkplaceKeyword] = useState<string>('');

  const [visitToList, setVisitToList] = useState<VisitTo[] | undefined>();
  const [showVisitPeriodCalendar, setShowVisitPeriodCalendar] = useState<boolean>(false);
  const [focusWorkplace, setFocusWorkplace] = useState<boolean>(false);
  const [showSearchEnterButton, setShowSearchEnterButton] = useState<boolean>(false);
  const [searchCondition, setSearchCondition] = useState<string>(
    t('reservation.label.ReservationBaseInfo.person.searchByEmail')
  );
  const [visitToName, setVisitToName] = useState<string>('');
  const [visitToPhone, setVisitToPhone] = useState<string>('');
  const [visitToEmail, setVisitToEmail] = useState<string>('');
  const [showUseSiteInfoModal, setShowUseSiteInfoModal] = useState<boolean>(false);
  const [visitToPhoneMask, setVisitToPhoneMask] = useState<string>('9999');
  const [myWorkplaceInfo, setMyWorkplaceInfo] = useState<Workplace | null>(null);
  const [visitHistory, setVisitHistory] = useState<VisitHistory[]>();

  const [showNonEmpUrlModalType, setShowNonEmpUrlModalType] = useState<MemberTypeCode>(MemberTypeCode.MEM);
  const [showNonEmpUrlModal, setShowNonEmpUrlModal] = useState<boolean>(false);
  const [selectedWorkplace, setSelectedWorkplace] = useState<Workplace>();
  const keywordCodeList = props.commonCodeMap?.get('searchKeywordCode');
  const logoImage = window.location.hostname.includes('singlex')
    ? localStorage.getItem('lang') === 'ko'
      ? singlexLogo
      : singlexLogoEng
    : vPortalLogo;
  const history = useHistory();
  const setLoading = (_isLoading: boolean) => {
    dispatch({ type: ActionType.LOADING, loading: { isLoading: _isLoading } });
  };

  const focusSearchWorkplace = async (): Promise<void> => {
    await getVisitHistoryByWorkplace();
    if (!workplaceKeyword && !props.errors.searchWorkplace) {
      setShowSearchWorkplace(true);
      setShowSearchWorkplaceResult(false);
    }
  };
  const blurSearchWorkplace = (): void => {
    setShowSearchWorkplace(false);
  };

  const clearSearchWorkplace = (): void => {
    if (checkVisitInfo()) {
      props.handleShowConfirmModal &&
        props.handleShowConfirmModal(t('reservation.label.ConfirmModal.clearWorkplace.message'), clearWorkplace);
    } else {
      clearWorkplace();
    }
  };

  const checkVisitInfo = (): boolean => {
    let result = false;
    Object.keys(props.values).forEach(key => {
      if (key !== 'workplace' && key !== 'isSessionVisitorDisabled' && key !== 'isPass') {
        if (key === 'visitors') {
          if (
            props.values.visitors.length > 1 ||
            (props.values.visitors.length === 1 && props.values.visitors[0].id !== userInfo?.id)
          ) {
            result = true;
          }
        } else if (props.values[key]) {
          result = true;
        }
      }
    });
    return result;
  };

  const clearWorkplace = (): void => {
    if (history.location.hash.includes('#confirmModal')) {
      history.goBack();
    }
    let visitorsArr: any = [];
    if (props.sessionUser?.memberTypeCode === MemberTypeCode.NO_MEM) {
      visitorsArr = props.values.visitors;
      if (visitorsArr && visitorsArr.length > 0) {
        visitorsArr[0].importedGoodsList = [];
        visitorsArr[0].fromVisitPeriod = '';
        visitorsArr[0].toVisitPeriod = '';
      }
    }
    props.setValues &&
      props.setValues({
        visitors: visitorsArr,
        isSessionVisitorDisabled: true,
        termsAgreeYn: userInfo?.termsAgreeYn,
      });

    props.setWorkplaceVisitInfo(undefined);
    setVisitToName('');
    setVisitToPhone('');
    setVisitToEmail('');
    setVisitToList([]);
    setShowSearchEnterButton(false);
    setFocusWorkplace(true);
    setShowSearchVisitToResult(false);
  };

  const focusSearchVisitTo = (): void => {
    if (props.visitHistory && props.visitHistory.length > 0) {
      const selectHistory = props.visitHistory.filter(
        history => history.workplace.workplaceId === props.workplace?.workplaceId
      );
      setVisitToHistoryByWorkplace(selectHistory);
    }

    if (!visitToName && !props.errors.searchVisitTo) {
      setShowSearchVisitTo(true);
      setShowSearchVisitToResult(false);
    }
  };
  const blurSearchVisitTo = (): void => {
    setShowSearchVisitTo(false);
  };
  const clearSearchVisitTo = (): void => {
    setVisitToName('');
    setVisitToPhone('');
    setVisitToEmail('');
    setVisitToList([]);
    setShowSearchEnterButton(false);
    setShowSearchVisitTo(true);
    setShowSearchVisitToResult(false);

    props.setFieldValue && props.setFieldValue('visitTo', null, props.checkIsSubmited(props.formProps));
  };

  const onClearVisitPeriod = (): void => {
    if (checkVisitDatesInfo()) {
      props.handleShowConfirmModal &&
        props.handleShowConfirmModal(t('reservation.label.ConfirmModal.clearVisitDates.message'), clearVisitPeriod);
    } else {
      clearVisitPeriod();
    }
  };

  const checkVisitDatesInfo = (): boolean => {
    let result = false;
    Object.keys(props.values).forEach(key => {
      if (key === 'visitors' && props.values.visitors.length > 0) {
        result = true;
      }
    });
    return result;
  };
  const clearVisitPeriod = (): void => {
    const visitors = [] as Visitor[];
    if (props.formProps.values.visitors) {
      props.formProps.values.visitors.map((visitor: Visitor, i: number) => {
        visitor.fromVisitPeriod = null;
        visitor.toVisitPeriod = null;
        visitors.push(visitor);
      });
    }
    props.setValues &&
      props.setValues({
        ...props.formProps.values,
        visitors: visitors,
        fromVisitPeriod: null,
        toVisitPeriod: null,
      });
    if (history.location.hash.includes('#confirmModal')) {
      history.replace('#calendar');
    } else {
      history.push(history.location.hash + '#calendar');
    }

    setShowVisitPeriodCalendar(true);
  };

  const onPeriodChange = (fromVisitPeriod: moment.Moment, toVisitPeriod: moment.Moment): void => {
    const visitors = [] as Visitor[];
    if (props.formProps.values.visitors) {
      props.formProps.values.visitors.map((visitor: Visitor, i: number) => {
        visitor.fromVisitPeriod = fromVisitPeriod.format(DateFormat.DATE_IF);
        visitor.toVisitPeriod = toVisitPeriod.format(DateFormat.DATE_IF);
        visitors.push(visitor);
      });
    }
    props.setValues &&
      props.setValues({
        ...props.formProps.values,
        visitors: visitors,
        fromVisitPeriod: fromVisitPeriod.format(DateFormat.DATE_IF),
        toVisitPeriod: toVisitPeriod.format(DateFormat.DATE_IF),
      });

    setShowVisitPeriodCalendar(false);
  };

  const handleVisitPeriodCalendar = (event: any) => {
    if (!props.workplace) {
      return;
    }

    if (showVisitPeriodCalendar) {
      history.goBack();
    } else {
      if (!history.location.hash.includes('#calendar')) {
        history.push(history.location.hash + '#calendar');
      }
    }
    setShowVisitPeriodCalendar(!showVisitPeriodCalendar);
  };
  const closeVisitPeriodCalendar = (event: any) => {
    event.stopPropagation();
    event.preventDefault();

    if (history.location.hash.includes('#calendar')) {
      history.goBack();
    }
    setShowVisitPeriodCalendar(false);
  };

  const onSelectWorkplaceHistory = (eventKey: any, event: Record<string, any>): void => {
    const selectedMemberId = eventKey.split(':')[0];
    const selectedWorkplaceId = eventKey.split(':')[1];
    const selectHistory = props.visitHistory!.filter(
      history => history.id === selectedMemberId && history.workplace.workplaceId === selectedWorkplaceId
    )[0];
    const selectWorkplace = selectHistory.workplace;
    if (selectHistory.companyName) {
      selectWorkplace.groupCompanyName = selectHistory.companyName;
    }

    const workplaceVisitInfo: WorkplaceVisitInfo = {
      workplaceNotice: selectWorkplace.workplaceNotice || '',
      groupCompanyName: selectWorkplace.groupCompanyName,
      workplace: selectWorkplace.workplace,
      requiredEducation: selectWorkplace.requiredEducation === 0 ? false : true,
      requestNotiYesorno: selectWorkplace.requestNotiYesorno === 'Y',
      approvalNotiYesorno: selectWorkplace.approvalNotiYesorno === 'Y',
    };

    props.setWorkplaceVisitInfo(workplaceVisitInfo);
    props.setFieldValue &&
      props.setFieldValue('workplace', selectWorkplace, props.checkIsSubmited(props.formProps)) &&
      props.setFieldValue(
        'visitTo',
        {
          id: selectHistory.id,
          name: selectHistory.name,
          employeeNumber: selectHistory.employeeNumber,
          companyId: selectHistory.companyId,
          companyName: selectHistory.companyName,
          dept: selectHistory.dept,
          position: selectHistory.position,
          type: selectHistory.type,
          emailAddress: selectHistory.emailAddress,
          phoneNumber: selectHistory.phoneNumber,
        },
        props.checkIsSubmited(props.formProps)
      );
  };

  const onSelectVisitToHistory = (eventKey: any, event: Record<string, any>): void => {
    const visitToHistory = visitToHistoryByWorkplace!.filter(history => history.id === eventKey)[0];
    props.setFieldValue &&
      props.setFieldValue(
        'visitTo',
        {
          id: visitToHistory.id,
          name: visitToHistory.name,
          employeeNumber: visitToHistory.employeeNumber,
          companyId: visitToHistory.companyId,
          companyName: visitToHistory.companyName,
          dept: visitToHistory.dept,
          position: visitToHistory.position,
          type: visitToHistory.type,
          emailAddress: visitToHistory.emailAddress,
          phoneNumber: visitToHistory.phoneNumber,
        },
        props.checkIsSubmited(props.formProps)
      );
  };

  const handleWorkplaceKeyword = (event: React.ChangeEvent<HTMLInputElement>): void => {
    if (!event.target.value) {
      setShowSearchWorkplace(true);
      setShowSearchWorkplaceResult(false);
    }
    setWorkplaceKeyword(event.target.value);
  };
  const handleEnterButton = (type: string, value: string) => {
    if (type === 'NAME') {
      if (value || visitToPhone || visitToEmail) {
        setShowSearchEnterButton(true);
      } else {
        setShowSearchEnterButton(false);
      }
    } else if (type === 'PHONE') {
      if (value || visitToName || visitToEmail) {
        setShowSearchEnterButton(true);
      } else {
        setShowSearchEnterButton(false);
      }
    } else if (type === 'EMAIL') {
      if (value || visitToName || visitToPhone) {
        setShowSearchEnterButton(true);
      } else {
        setShowSearchEnterButton(false);
      }
    }
  };
  const handleVisitToName = (event: React.ChangeEvent<HTMLInputElement>): void => {
    if (!event.target.value) {
      setShowSearchVisitTo(true);
      setShowSearchVisitToResult(false);
    }
    handleEnterButton('NAME', event.target.value);
    setVisitToName(event.target.value);
  };
  const handleVisitToPhoneMask = (newState, oldState, userInput) => {
    const newValue: string = newState.value;
    const oldValue: string = oldState.value;
    const regexN = /[^0-9]/;

    if (!searchByEmail && (userInput || newValue !== oldValue) && !newValue && !props.values.isSubmited) {
      setShowSearchVisitTo(true);
      setShowSearchVisitToResult(false);
    }

    if ((userInput === null || oldValue.length < 4) && (userInput !== ' ' || regexN.test(userInput))) {
      return newState;
    } else {
      return oldState;
    }
  };

  const handleVisitToPhone = (event: React.ChangeEvent<HTMLInputElement>) => {
    handleEnterButton('PHONE', event.target.value);
    setVisitToPhone(event.target.value);
  };
  const handleVisitToEmail = (event: React.ChangeEvent<HTMLInputElement>): void => {
    if (!event.target.value) {
      setShowSearchVisitTo(true);
      setShowSearchVisitToResult(false);
    }
    handleEnterButton('EMAIL', event.target.value);
    setVisitToEmail(event.target.value);
  };

  const onSelectWorkplace = (eventKey: any, event: Record<string, any>): void => {
    const selectWorkplace = workplaceList!.filter(workplace => workplace.workplaceId === eventKey)[0];

    setWorkplaceKeyword('');
    setShowSearchWorkplace(false);
    setShowSearchWorkplaceResult(false);

    const memberTypeCode = sessionStorage.getItem('memberTypeCode');

    const workplaceVisitInfo: WorkplaceVisitInfo = {
      workplaceNotice: selectWorkplace.workplaceNotice || '',
      groupCompanyName: selectWorkplace.groupCompanyName,
      workplace: selectWorkplace.workplace,
      requiredEducation: selectWorkplace.requiredEducation === 0 ? false : true,
      requestNotiYesorno: selectWorkplace.requestNotiYesorno === 'Y',
      approvalNotiYesorno: selectWorkplace.approvalNotiYesorno === 'Y',
    };

    /* eslint-disable */
    if (memberTypeCode === MemberTypeCode.MEM && selectWorkplace.registerMemberYesorno === 'N') {
      history.push(history.location.hash + '#noneEmpUrlModal');
      setShowNonEmpUrlModalType(memberTypeCode);
      setSelectedWorkplace(selectWorkplace);
      setShowNonEmpUrlModal(true);
    } else if (memberTypeCode === MemberTypeCode.NO_MEM && selectWorkplace.registerNoneMemberYesorno === 'N') {
      history.push(history.location.hash + '#noneEmpUrlModal');
      setShowNonEmpUrlModalType(memberTypeCode);
      setSelectedWorkplace(selectWorkplace);
      setShowNonEmpUrlModal(true);
    } else {
      props.setWorkplaceVisitInfo(workplaceVisitInfo);
      props.setFieldValue && props.setFieldValue('workplace', selectWorkplace, props.checkIsSubmited(props.formProps));
    }
    /* eslint-enable */
  };
  const onSelectWorkplaceKeyword = (eventKey: any, event: Record<string, any>): void => {
    setWorkplaceKeyword(eventKey);
    searchWorkplace(eventKey);
  };

  const searchWorkplace = async (workplaceName: string): Promise<void> => {
    if (!workplaceName) {
      return;
    }
    setLoading(true);
    const workplaceList: Workplace[] | null = await workplaceService.getWorkplaceByKeyword(workplaceName);
    setLoading(false);
    if (workplaceList?.length === 1) {
      setWorkplaceKeyword('');
      setShowSearchWorkplace(false);
      setShowSearchWorkplaceResult(false);
      const selectWorkplace = workplaceList[0];
      const memberTypeCode = sessionStorage.getItem('memberTypeCode');

      /* eslint-disable */
      if (memberTypeCode === MemberTypeCode.MEM && selectWorkplace.registerMemberYesorno === 'N') {
        setShowNonEmpUrlModalType(memberTypeCode);
        setSelectedWorkplace(selectWorkplace);
        setShowNonEmpUrlModal(true);
        history.push(history.location.hash + '#noneEmpUrlModal');
      } else if (memberTypeCode === MemberTypeCode.NO_MEM && selectWorkplace.registerNoneMemberYesorno === 'N') {
        setShowNonEmpUrlModalType(memberTypeCode);
        setSelectedWorkplace(selectWorkplace);
        setShowNonEmpUrlModal(true);
        history.push(history.location.hash + '#noneEmpUrlModal');
      } else {
        const workplaceVisitInfo: WorkplaceVisitInfo = {
          workplaceNotice: selectWorkplace.workplaceNotice || '',
          groupCompanyName: selectWorkplace.groupCompanyName,
          workplace: selectWorkplace.workplace,
          requiredEducation: selectWorkplace.requiredEducation === 0 ? false : true,
          requestNotiYesorno: selectWorkplace.requestNotiYesorno === 'Y',
          approvalNotiYesorno: selectWorkplace.approvalNotiYesorno === 'Y',
        };
        props.setWorkplaceVisitInfo(workplaceVisitInfo);

        props.setFieldValue &&
          props.setFieldValue('workplace', selectWorkplace, props.checkIsSubmited(props.formProps));
      }
      /* eslint-enable */
    } else {
      setWorkplaceList(workplaceList);
      setShowSearchWorkplace(false);
      setShowSearchWorkplaceResult(true);
    }
  };

  const searchVisitTo = async (): Promise<void> => {
    if (!((visitToName.trim() && visitToPhone) || visitToEmail.trim()) || !props.workplace) {
      return;
    }
    if (!visitToEmail && !ReservationConstants.REGEXP_MOBILE_MEMBER.test(visitToPhone)) {
      props.formProps.setFieldError('visitTo', null);
      props.formProps.setFieldError(
        'visitToPhone',
        t('reservation.label.ReservationBaseInfo.person.exception.mobileFormat')
      );
      setShowSearchVisitTo(false);
      setShowSearchVisitToResult(false);

      return;
    }

    let visitToList: VisitTo[] | undefined;
    if (props.workplace) {
      const searchInfo: SearchVisitToInfo = {
        companyCode: props.workplace.groupCompanyId,
        employeeName: visitToName,
        phoneNumber: visitToPhone,
        emailAddress: visitToEmail,
        visitToPartnerYesorno: props.workplace.visitToPartnerYesorno,
        visitToTemporaryYesorno: props.workplace.visitToTemporaryYesorno,
      };
      setLoading(true);
      const response: any = await memberServiece.searchEmployee(searchInfo);
      setLoading(false);
      if (response && response.length !== 0) {
        visitToList = response;
      }
    }
    if (visitToList?.length === 1) {
      props.setFieldValue && props.setFieldValue('visitTo', visitToList[0], props.checkIsSubmited(props.formProps));
    } else {
      setVisitToList(visitToList);
      setShowSearchVisitTo(false);
      setShowSearchVisitToResult(true);
    }
  };

  const onSelectVisitTo = (eventKey: any, event: Record<string, any>): void => {
    const selectedVisitTo = visitToList!.filter(visitTo => visitTo.id === eventKey)[0];

    props.setFieldValue && props.setFieldValue('visitTo', selectedVisitTo, props.checkIsSubmited(props.formProps));
  };

  const getVisitHistoryByWorkplace = async (): Promise<VisitHistory[] | undefined> => {
    let visitToHistory: VisitHistory[] = [];

    if (visitHistory) {
      props.setFieldValue && props.setFieldValue('visitHistory', visitHistory, props.checkIsSubmited(props.formProps));
    } else if (props.sessionUser?.memberTypeCode !== MemberTypeCode.NO_MEM) {
      setLoading(true);
      visitToHistory = await reservationService.getLatestReservationList();
      setLoading(false);
      if (visitToHistory && visitToHistory.length > 0) {
        props.setFieldValue &&
          props.setFieldValue('visitHistory', visitToHistory, props.checkIsSubmited(props.formProps));
        setVisitHistory(visitToHistory);
      }
    }
    return visitToHistory;
  };

  const getSearchDropDown = (searchType: string): ReactElement | null => {
    /* eslint-disable */
    if (searchType === 'workplace') {
      return (
        <div className="dropDown">
          {props.visitHistory &&
            props.visitHistory.map((history, index) => (
              <Dropdown.Item
                key={index}
                eventKey={history.id + ':' + history.workplace.workplaceId}
                onSelect={onSelectWorkplaceHistory}
                id="workplaceHistoryDropdown"
                data-testid="workplaceHistoryDropdown"
                className="multiLine workplace d-inline-block"
              >
                <i className="material-icons">rotate_left</i>
                <p className="d-inline-block">
                  <strong>
                    {history.companyName}&nbsp;
                    {history.workplace?.workplace}
                  </strong>
                  <br />
                  {history.name}&nbsp;{history.position} / {history.dept}
                </p>
              </Dropdown.Item>
            ))}
          <div>
            {keywordCodeList?.map((keywordCode) => (
              <Dropdown.Item
                eventKey={keywordCode.codeName}
                className="oneLine recommendWorkplace d-inline-block"
                onSelect={onSelectWorkplaceKeyword}
                id="recommendWorkplace"
                data-testid="recommendWorkplace"
              >
                <Image src={searchWhite16Icon} />
                <p className="d-inline">
                  <strong>{keywordCode.codeName}</strong>
                </p>
              </Dropdown.Item>
            ))}
          </div>
        </div>
      );
    } else {
      if (props.visitHistory && props.workplace) {
        if (visitToHistoryByWorkplace && visitToHistoryByWorkplace.length > 0) {
          return (
            <div className="dropDown">
              {visitToHistoryByWorkplace.map((history, index) => (
                <Dropdown.Item
                  key={index}
                  eventKey={history.id}
                  onSelect={onSelectVisitToHistory}
                  id="visitToHistoryDropdown"
                  data-testid="visitToHistoryDropdown"
                  className="oneLine visitTo d-inline-block"
                >
                  <i className="material-icons">rotate_left</i>
                  <p className="d-inline-block">
                    <strong>
                      {history.name}&nbsp;{history.position}
                    </strong>
                    &nbsp;/&nbsp;{history.companyName}&nbsp;{history.dept}
                  </p>
                </Dropdown.Item>
              ))}
            </div>
          );
        }
      } else if (props.visitHistory) {
        return (
          <div className="dropDown">
            {props.visitHistory.map((history, index) => (
              <Dropdown.Item
                key={index}
                eventKey={history.id}
                onSelect={onSelectVisitToHistory}
                id="visitToHistoryDropdown"
                data-testid="visitToHistoryDropdown"
                className="oneLine visitTo d-inline-block"
              >
                <i className="material-icons visitToHistory">rotate_left</i>
                <p className="d-inline-block">
                  <strong>
                    {history.name}&nbsp;{history.position}
                  </strong>
                  &nbsp;/&nbsp;{history.companyName}&nbsp;{history.dept}
                </p>
              </Dropdown.Item>
            ))}
          </div>
        );
      }
    }
    return null;
  };
  /* eslint-enable */
  const getSearchResult = (searchType: string): ReactElement | null => {
    if (searchType === 'workplace') {
      return (
        <div className="dropDown">
          {workplaceList &&
            workplaceList.map((workplace, index) => (
              <OverlayTrigger
                key={index}
                overlay={
                  <Tooltip id="tooltip-disabled">
                    {workplace.groupCompanyName}&nbsp;{workplace.workplace}
                  </Tooltip>
                }
              >
                <div>
                  <Dropdown.Item
                    key={index}
                    eventKey={workplace.workplaceId}
                    onSelect={onSelectWorkplace}
                    id="searchWorkplaceResult"
                    data-testid="searchWorkplaceResult"
                    className="oneLine workplace searchWorkplaceResult"
                  >
                    {workplace.workplaceType === 'office' ? <Image src={officeIcon} /> : <Image src={factoryIcon} />}
                    <p className="d-inline-block">
                      <strong>
                        {workplace.groupCompanyName}&nbsp;
                        {workplace.workplace}
                      </strong>
                    </p>
                  </Dropdown.Item>
                </div>
              </OverlayTrigger>
            ))}
          {(!workplaceList || workplaceList.length === 0) && (
            <Dropdown.Item id="searchWorkplaceNoResult" className="noResult" data-testid="searchWorkplaceNoResult">
              <Image src={noResultIcon} />
              <p className="d-inline-block">
                <strong>{t('common.message.info.search.nodata')}</strong>
              </p>
            </Dropdown.Item>
          )}
        </div>
      );
    } else if (searchType === 'employee') {
      return (
        <div className="dropDown">
          {visitToList &&
            visitToList.map((visitTo, index) => (
              <Dropdown.Item
                key={index}
                eventKey={visitTo.id}
                onSelect={onSelectVisitTo}
                id="searchVisitToResult"
                data-testid="searchVisitToResult"
                className="oneLine visitTo searchVisitToResult"
              >
                <Image src={dropDownPersonIcon} />
                <p className="d-inline-block">
                  <strong>
                    {visitTo.name}&nbsp;{visitTo.position}
                  </strong>
                  &nbsp;/&nbsp;{visitTo.companyName}&nbsp;{visitTo.dept}
                </p>
              </Dropdown.Item>
            ))}
          {(!visitToList || visitToList.length === 0) && (
            <Dropdown.Item id="searchVisitToNoResult" className="noResult" data-testid="searchVisitToNoResult">
              <Image src={noResultIcon} />
              <p className="d-inline-block">
                <strong>{t('common.message.info.search.nodata')}</strong>
              </p>
            </Dropdown.Item>
          )}
        </div>
      );
    }
    return null;
  };

  const changeSearchCondition = (): void => {
    if (props.workplace) {
      setShowSearchVisitToResult(false);
      if (searchByEmail) {
        setSearchByEmail(false);
        setSearchCondition(t('reservation.label.ReservationBaseInfo.person.searchByEmail'));
        setVisitToEmail('');
        setShowSearchEnterButton(false);
      } else {
        props.formProps.setFieldError('visitToPhone', null);
        setSearchByEmail(true);
        setSearchCondition(t('reservation.label.ReservationBaseInfo.person.searchByNamePhone'));
        setVisitToName('');
        setVisitToPhone('');
        setShowSearchEnterButton(false);
      }
      setVisitToList([]);
    }
  };
  const onSearchVisitToEnter = (e: any): void => {
    if (e.key === 'Enter') {
      searchVisitTo();
    }
  };

  const setVisitPeriod = (days: number): void => {
    const dates = moment().add(days, 'days');
    onPeriodChange(dates, dates);
  };

  const handleShowUseSiteInfo = (): void => {
    setShowUseSiteInfoModal(!showUseSiteInfoModal);
  };

  /* eslint-disable */
  useEffect(() => {
    const getMyWorkplace = async () => {
      if (userInfo && userInfo.mainWorkplaceId) {
        setLoading(true);
        let result = await workplaceService.getWorkplaceByKeyword(userInfo.mainWorkplaceId, true);
        setLoading(false);
        return result;
      }
    };

    getMyWorkplace().then(res => {
      if (res && res.length > 0) {
        setMyWorkplaceInfo(res[0]);
      }
    });
  }, []);
  useEffect(() => {
    const location = history.location;

    if (!location.hash.includes('#noneEmpUrlModal')) {
      setShowNonEmpUrlModal(false);
    }
  }, [history.location]);
  /* eslint-enable */

  const onClickMyWorkplace = (): void => {
    if (!myWorkplaceInfo) {
      return;
    }
    const workplaceVisitInfo: WorkplaceVisitInfo = {
      workplaceNotice: myWorkplaceInfo.workplaceNotice || '',
      groupCompanyName: myWorkplaceInfo.groupCompanyName,
      workplace: myWorkplaceInfo.workplace,
      requiredEducation: myWorkplaceInfo.requiredEducation === 0 ? false : true,
      requestNotiYesorno: myWorkplaceInfo.requestNotiYesorno === 'Y',
      approvalNotiYesorno: myWorkplaceInfo.approvalNotiYesorno === 'Y',
    };
    props.setWorkplaceVisitInfo(workplaceVisitInfo);
    props.setFieldValue && props.setFieldValue('workplace', myWorkplaceInfo, props.checkIsSubmited(props.formProps));

    setWorkplaceKeyword('');
    setShowSearchWorkplace(false);
    setShowSearchWorkplaceResult(false);
  };

  const onClickInputMyInfo = (): void => {
    if (!userInfo || !userInfo.employeeNumber || !userInfo.companyCode || !userInfo.emailAddress) {
      return;
    }
    const visitTo: VisitTo = {
      id: userInfo.id,
      name: userInfo.name,
      employeeNumber: userInfo.employeeNumber,
      companyId: userInfo.companyCode,
      companyName: userInfo.companyName,
      dept: userInfo.dept,
      position: userInfo.position,
      type: userInfo.type,
      emailAddress: userInfo.emailAddress,
      phoneNumber: userInfo.mobile,
    };
    props.setFieldValue && props.setFieldValue('visitTo', visitTo, props.checkIsSubmited(props.formProps));
    setShowSearchVisitTo(false);
    setShowSearchVisitToResult(false);
  };

  const handleShowNonEmpUrlModal = (): void => {
    history.goBack();
  };

  return (
    <div className="ReservationBaseInfo">
      <Modal
        id="useSiteInfoModal"
        data-testid="useSiteInfoModal"
        show={showUseSiteInfoModal}
        onHide={handleShowUseSiteInfo}
        className="useSiteInfoModal"
        enforceFocus={false}
      >
        <Modal.Header closeButton></Modal.Header>
        <Image
          className="logo"
          src={i18n.language === 'ko' ? '../visitportal-Guide-new-kor.png' : '../visitportal-Guide-eng.png'}
        />
      </Modal>
      {selectedWorkplace && (
        <WorkplaceAlertModal
          workplace={selectedWorkplace}
          alertType={showNonEmpUrlModalType}
          show={showNonEmpUrlModal}
          onClose={handleShowNonEmpUrlModal}
        />
      )}
      <div className="header">
        <Image className="logo" src={logoImage} id={logoImage === vPortalLogo ? '' : 'singlexLogo'} />

        <Button
          id="useSiteInfoButton"
          variant="light"
          className="useSiteInfoButton"
          data-testid="useSiteInfoButton"
          onClick={handleShowUseSiteInfo}
        >
          {' '}
          <Image
            src={questionMarkIcon}
            id="questionMarkIcon"
            data-testid="questionMarkIcon"
            className="questionMarkIcon"
          />
          {t('reservation.label.ReservationBaseInfo.header.useSite')}
        </Button>
      </div>
      <CardDeck className="body">
        <Card bg="primary" text="white" className="on">
          {props.workplace && (
            <Card.Body className="overlay workplaceCard" id="checkedWorkplace" data-testid="checkedWorkplace">
              <i className="material-icons md-76">done</i>
              <Card.Title>
                <Image src={placeIcon} />
                <span
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(
                      t('reservation.label.ReservationBaseInfo.workplace.title', {
                        interpolation: { escapeValue: false },
                      })
                    ),
                  }}
                ></span>
              </Card.Title>
              <Card.Text id="checkedWorkplaceName" className="checkedWorkplaceName" data-testid="checkedWorkplaceName">
                <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">{props.workplace.groupCompanyName}</Tooltip>}>
                  <strong>{props.workplace.groupCompanyName}</strong>
                </OverlayTrigger>
                <br />
                <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">{props.workplace.workplace}</Tooltip>}>
                  <strong>{props.workplace.workplace}</strong>
                </OverlayTrigger>
                <br />
                <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">{props.workplace.workplaceAddress}</Tooltip>}>
                  <p>{props.workplace.workplaceAddress}</p>
                </OverlayTrigger>
              </Card.Text>
              <Card.Text
                className="overlayText pointer d-inline"
                onClick={clearSearchWorkplace}
                id="clearWorkplace"
                data-testid="clearWorkplace"
              >
                {t('reservation.label.button.change')}
              </Card.Text>
            </Card.Body>
          )}
          {!props.workplace && (
            <Card.Body className="workplaceCard">
              <Card.Title>
                <span>
                  <Image src={placeIcon} />
                </span>
                <p
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(
                      t('reservation.label.ReservationBaseInfo.workplace.title', {
                        interpolation: { escapeValue: false },
                      })
                    ),
                  }}
                ></p>
                <i className="material-icons md-32">chevron_right</i>
              </Card.Title>
              <OutsideClickHandler onOutsideClick={blurSearchWorkplace}>
                {userInfo?.mainWorkplaceId && (
                  <Card.Text className={'text-right pointer'} id="setMyWorkplace" data-testid="setMyWorkplace">
                    <span
                      className="d-inline-block"
                      id="setMyWorkplace"
                      data-testid="setMyWorkplace"
                      onClick={onClickMyWorkplace}
                    >
                      {t('reservation.label.ReservationBaseInfo.workplace.InputMainWorkplace')}
                    </span>
                  </Card.Text>
                )}
                <InputGroup
                  className={props.sessionUser?.mainWorkplaceId ? 'visitWorkplaceMyWorkplace' : 'visitWorkplace'}
                >
                  <InputGroup.Prepend>
                    <Image
                      id="searchWorkplaceIcon"
                      data-testid="searchWorkplaceIcon"
                      src={searchBlack24Icon}
                      onClick={e => {
                        searchWorkplace(workplaceKeyword);
                      }}
                      className="searchIcon"
                    />
                  </InputGroup.Prepend>
                  <FormControl
                    autoFocus={focusWorkplace ? true : false}
                    id="workplaceKeyword"
                    data-testid="workplaceKeyword"
                    placeholder={t('reservation.label.ReservationBaseInfo.workplace.placeholder')}
                    onFocus={focusSearchWorkplace}
                    name="workplaceKeyword"
                    value={workplaceKeyword || ''}
                    onChange={handleWorkplaceKeyword}
                    onKeyPress={(e: any) => {
                      if (e.key === 'Enter') {
                        searchWorkplace(e.target.value);
                      }
                    }}
                    autoComplete={'off'}
                  />
                </InputGroup>
                {showSearchWorkplace && getSearchDropDown('workplace')}
                {showSearchWorkplaceResult && getSearchResult('workplace')}
              </OutsideClickHandler>
              {(props.values.isSubmited || props.values.isTempSaved) &&
                !showSearchWorkplace &&
                !showSearchWorkplaceResult &&
                props.errors.workplace && (
                  <div
                    id="checkWorkplaceValidationMessage"
                    data-testid="checkWorkplaceValidationMessage"
                    className={'text-danger validationMessage'}
                  >
                    <span>
                      {t('common.message.exception.requiredFormat', {
                        0: t('reservation.label.ReservationBaseInfo.workplace.name'),
                      })}
                    </span>
                  </div>
                )}
            </Card.Body>
          )}
        </Card>
        <Card bg="dark" text="white" className={props.workplace && 'on'}>
          {props.visitTo && (
            <Card.Body className="overlay visitToBody" id="checkedVisitTo" data-testid="checkedVisitTo">
              <i className="material-icons md-76">done</i>
              <Card.Title>
                <Image src={personIcon} />
                <span
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(
                      t('reservation.label.ReservationBaseInfo.person.title', {
                        interpolation: { escapeValue: false },
                      })
                    ),
                  }}
                ></span>
              </Card.Title>
              <Card.Text id="checkedVisitToName" data-testid="checkedVisitToName" className="checkedVisitToName">
                <strong>
                  {props.visitTo.name}&nbsp;
                  {props.visitTo.position}
                </strong>
                <br />
                {props.visitTo.companyName}&nbsp;{props.visitTo.dept}
              </Card.Text>
              <Card.Text
                className="overlayText pointer d-inline"
                onClick={clearSearchVisitTo}
                id="clearVisitTo"
                data-testid="clearVisitTo"
              >
                {t('reservation.label.button.change')}
              </Card.Text>
            </Card.Body>
          )}
          {!props.visitTo && (
            <Card.Body className="visitToBody">
              <Card.Title>
                <span>
                  <Image src={personIcon} />
                </span>
                <p
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(
                      t('reservation.label.ReservationBaseInfo.person.title', {
                        interpolation: { escapeValue: false },
                      })
                    ),
                  }}
                ></p>
                <i className="material-icons md-32">chevron_right</i>
              </Card.Title>
              <OutsideClickHandler onOutsideClick={blurSearchVisitTo}>
                {props.workplace && (
                  <Card.Text
                    className={props.workplace ? 'text-right pointer' : 'text-right disabled'}
                    id="changeCondition"
                    data-testid="changeCondition"
                  >
                    {props.sessionUser?.memberTypeCode === MemberTypeCode.EMP &&
                      userInfo?.companyCode === props.values.workplace.groupCompanyId && (
                        <span
                          className="d-inline-block"
                          id="inputMyInfo"
                          data-testid="inputMyInfo"
                          onClick={onClickInputMyInfo}
                        >
                          {t('reservation.label.ReservationBaseInfo.person.InputMyInfo')}
                        </span>
                      )}
                    <span
                      className="d-inline-block"
                      id="searchCondition"
                      data-testid="searchCondition"
                      onClick={changeSearchCondition}
                    >
                      {searchCondition}
                    </span>
                  </Card.Text>
                )}

                <InputGroup className={props.workplace ? 'visitToKeyword' : 'visitToKeyword disabled'}>
                  <InputGroup.Prepend>
                    <Image
                      src={searchBlack24Icon}
                      id="searchVisitToIcon"
                      data-testid="searchVisitToIcon"
                      onClick={e => {
                        searchVisitTo();
                      }}
                      className="searchIcon"
                    />
                  </InputGroup.Prepend>
                  <FormControl
                    autoFocus={!props.workplace ? false : true}
                    className={searchByEmail ? 'visitToName hide' : 'visitToName visible col-md-4'}
                    id="visitToName"
                    data-testid="visitToName"
                    placeholder={t('reservation.label.ReservationBaseInfo.person.employeeName')}
                    aria-label="employeeName"
                    onFocus={focusSearchVisitTo}
                    value={visitToName || ''}
                    onChange={handleVisitToName}
                    onKeyPress={onSearchVisitToEnter}
                    disabled={props.workplace ? false : true}
                    autoComplete={'off'}
                  />
                  <InputMask
                    mask={visitToPhoneMask}
                    formatChars={{
                      '9': '[0-9]',
                    }}
                    maskChar={null}
                    value={visitToPhone || ''}
                    onFocus={focusSearchVisitTo}
                    disabled={props.workplace ? false : true}
                    readOnly={props.workplace ? false : true}
                    beforeMaskedValueChange={handleVisitToPhoneMask}
                    onChange={handleVisitToPhone}
                  >
                    {(inputProps: any) => (
                      <FormControl
                        className={searchByEmail ? 'visitToPhone hide' : 'visitToPhone col-md-7'}
                        id="visitToPhone"
                        data-testid="visitToPhone"
                        placeholder={t('reservation.label.ReservationBaseInfo.person.phone')}
                        aria-label="mobile"
                        value={inputProps.visitToPhone}
                        onKeyPress={onSearchVisitToEnter}
                        readOnly={props.workplace ? false : true}
                        autoComplete={'off'}
                      />
                    )}
                  </InputMask>

                  <FormControl
                    autoFocus={!props.workplace ? false : true}
                    className={!searchByEmail ? 'visitToEmail hide' : 'visitToEmail'}
                    id="visitToEmail"
                    data-testid="visitToEmail"
                    placeholder={t('reservation.label.ReservationBaseInfo.person.email')}
                    aria-label="employeeName"
                    onFocus={focusSearchVisitTo}
                    value={visitToEmail || ''}
                    onChange={handleVisitToEmail}
                    onKeyPress={onSearchVisitToEnter}
                    disabled={props.workplace ? false : true}
                    autoComplete={'off'}
                  />

                  <div>
                    {showSearchEnterButton && (
                      <Image
                        src={enter24Icon}
                        onClick={searchVisitTo}
                        data-testid="enter-search-cond"
                        className={'d-inline-block enter-search-cond2 '}
                      />
                    )}
                  </div>
                </InputGroup>
                {props.workplace && showSearchVisitTo && props.visitHistory && getSearchDropDown('employee')}
                {props.workplace && showSearchVisitToResult && getSearchResult('employee')}
              </OutsideClickHandler>
              {(props.values.isSubmited || props.values.isTempSaved) &&
                !showSearchVisitTo &&
                !showSearchVisitToResult &&
                props.errors.visitTo && (
                  <div
                    id="checkVisitToValidationMessage"
                    data-testid="checkVisitToValidationMessage"
                    className={'text-danger validationMessage'}
                  >
                    <span>
                      {' '}
                      {t('common.message.exception.requiredFormat', {
                        0: t('reservation.label.ReservationBaseInfo.person.name'),
                      })}
                    </span>
                  </div>
                )}
              {!showSearchVisitTo && !showSearchVisitToResult && props.errors.visitToPhone && (
                <div
                  id="checkVisitToValidationMessage"
                  data-testid="checkVisitToValidationMessage"
                  className={'text-danger validationMessage'}
                >
                  <span> {props.errors.visitToPhone}</span>
                </div>
              )}
            </Card.Body>
          )}
        </Card>
        <Card bg="secondary" text="white" className={props.visitTo && 'on'}>
          {props.toVisitPeriod && (
            <Card.Body className="overlay datesBody" id="checkedDates" data-testid="checkedDates">
              <i className="material-icons md-76">done</i>
              <Card.Title>
                <Image src={scheduleIcon} />

                <span
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(
                      t('reservation.label.ReservationBaseInfo.dates.title', {
                        interpolation: { escapeValue: false },
                      })
                    ),
                  }}
                ></span>
              </Card.Title>
              <Card.Text>
                <strong>
                  {fromVisitPeriod?.format(DateFormat.DATE_DISP) === toVisitPeriod?.format(DateFormat.DATE_DISP)
                    ? fromVisitPeriod?.format(DateFormat.DATE_DISP)
                    : fromVisitPeriod?.format(DateFormat.DATE_DISP) +
                      ' ~ ' +
                      toVisitPeriod?.format(DateFormat.DATE_DISP)}
                </strong>
                <br />
                {fromVisitPeriod && toVisitPeriod && toVisitPeriod.diff(fromVisitPeriod, 'days') + 1}
                {fromVisitPeriod && toVisitPeriod && toVisitPeriod.diff(fromVisitPeriod, 'days') > 0
                  ? t('reservation.label.ReservationBaseInfo.calendar.days')
                  : t('reservation.label.ReservationBaseInfo.calendar.day')}
              </Card.Text>
              <Card.Text
                className="overlayText pointer d-inline"
                onClick={onClearVisitPeriod}
                id="clearCalendar"
                data-testid="clearCalendar"
              >
                {t('reservation.label.button.change')}
              </Card.Text>
            </Card.Body>
          )}
          {!props.toVisitPeriod && (
            <Card.Body className="datesBody">
              <Card.Title id="calendarInputTitle" data-testid="calendarInputTitle">
                <span>
                  {' '}
                  <Image src={scheduleIcon} />
                </span>
                <p
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(
                      t('reservation.label.ReservationBaseInfo.dates.title', {
                        interpolation: { escapeValue: false },
                      })
                    ),
                  }}
                ></p>
              </Card.Title>
              {props.workplace && (
                <Card.Text className={props.workplace ? 'text-right pointer' : 'text-right disabled'}>
                  <span
                    id="setToday"
                    data-testid="setToday"
                    className="d-inline-block"
                    onClick={(e: any) => {
                      e.preventDefault();
                      setVisitPeriod(0);
                    }}
                  >
                    {t('reservation.label.ReservationBaseInfo.calendar.button.today')}
                  </span>
                  <span
                    id="setTomorrow"
                    data-testid="setTomorrow"
                    className="d-inline-block"
                    onClick={(e: any) => {
                      e.preventDefault();
                      setVisitPeriod(1);
                    }}
                  >
                    {t('reservation.label.ReservationBaseInfo.calendar.button.tomorrow')}
                  </span>
                </Card.Text>
              )}
              <OutsideClickHandler onOutsideClick={closeVisitPeriodCalendar}>
                <InputGroup
                  id="calendarInput"
                  data-testid="calendarInput"
                  onClick={handleVisitPeriodCalendar}
                  className={props.workplace ? 'visitDates' : 'visitDates disabled'}
                >
                  <InputGroup.Prepend>
                    <Image src={calendarIcon} className="calendarIcon" />
                  </InputGroup.Prepend>
                  <FormControl
                    id="visitDates"
                    data-testid="visitDates"
                    placeholder={
                      props.workplace
                        ? t('reservation.label.ReservationBaseInfo.dates.placeholder', props.workplace)
                        : t('reservation.label.ReservationBaseInfo.dates.placeholder2')
                    }
                    aria-label="calendar"
                    readOnly
                    disabled
                  />
                </InputGroup>
                {showVisitPeriodCalendar && (
                  <VisitPeriodCalendar
                    show={showVisitPeriodCalendar}
                    fromVisitPeriod={fromVisitPeriod}
                    toVisitPeriod={toVisitPeriod}
                    onPeriodChange={onPeriodChange}
                    maxVisitPeriod={props.workplace?.maxVisitPeriod}
                    isCalendarAwalysOpen={true}
                    setShowCalendar={setShowVisitPeriodCalendar}
                  ></VisitPeriodCalendar>
                )}
              </OutsideClickHandler>
              {(props.values.isSubmited || props.values.isTempSaved) &&
                !showVisitPeriodCalendar &&
                props.errors.toVisitPeriod && (
                  <div
                    id="checkDatesValidationMessage"
                    data-testid="checkDatesValidationMessage"
                    className={'text-danger validationMessage'}
                  >
                    <span>
                      {t('common.message.exception.requiredFormat', {
                        0: t('reservation.label.ReservationBaseInfo.dates.name'),
                      })}
                    </span>
                  </div>
                )}
            </Card.Body>
          )}
        </Card>
      </CardDeck>
    </div>
  );
};

export default ReservationBaseInfo;
