import React, { useState } from 'react';
import './PopupNoticeModal.scss';
import { Modal, Card, Button, Row, Col, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { NoticeList } from '../model/Workplace';
import moment from 'moment';
import DOMPurify from 'dompurify';
import { DateFormat } from '../model/ReservationConstants';

export interface PopupNoticeModalI {
  onHide: (disablePopup: boolean) => void;
  notice: NoticeList;
}

export const PopupNoticeModal: React.FC<PopupNoticeModalI> = (props: PopupNoticeModalI) => {
  const { t } = useTranslation();
  const [disableChecked, setDisableChecked] = useState<boolean>(false);
  const getRegisterDate = registerDate => {
    return moment(registerDate).format(DateFormat.DATE_DISP);
  };
  const path = process.env.REACT_APP_S3_FILE_DOWNLOAD_URL || '';
  const filesizeFormatter = (bytes: number): string => {
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    if (bytes === 0) return '0 Byte';
    const i = Math.floor(Math.log(bytes) / Math.log(1024));
    return '(' + Math.round((bytes / Math.pow(1024, i)) * 100) / 100 + sizes[i] + ')';
  };
  return (
    <Modal
      show={true}
      onHide={() => {
        props.onHide(disableChecked);
      }}
      centered={true}
      className="popup-notice-modal"
      data-testid="popup-notice-modal"
      id="popupNoticeModal"
    >
      <Modal.Header closeButton>
        <div className="title" data-testid="notice-modal-header">
          <span className={props.notice.importanceYn === 'Y' ? 'importance' : ''}>{props.notice.noticeTitle}</span>
          {/* <span className="registerDate">{getRegisterDate(props.notice.registerDate)}</span> */}
        </div>
      </Modal.Header>
      <Modal.Body>
        {props.notice.fileOriginalName !== null ? (
          <Row>
            <Col md="auto">
              <i className="material-icons">get_app</i>
            </Col>
            <Col>
              <a
                href={path + props.notice.filePath + '/' + props.notice.fileOriginalName}
                target="_blank"
                download={props.notice.fileName}
              >
                {t('reservation.label.PopupNoticeModal.attatchedFile') +
                  props.notice.fileName +
                  '.' +
                  props.notice.fileExtension +
                  ' ' +
                  filesizeFormatter(props.notice.fileSize)}
              </a>
            </Col>
          </Row>
        ) : (
          ''
        )}
        <Row>
          <span
            className="center"
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(props.notice.noticeContent.replace(/(\n|\r\n)/g, '<br>')),
            }}
          />
        </Row>
        <Row>
          <Button
            className="btn-ok"
            onClick={() => {
              props.onHide(disableChecked);
            }}
          >
            {t('reservation.label.PopupNoticeModal.confirm')}
          </Button>

          <Form.Check
            id="disableForToday"
            name="disableForToday"
            data-testid="disableForToday"
            type="checkbox"
            label={t('reservation.label.PopupNoticeModal.disableForToday')}
            checked={disableChecked}
            onChange={e => {
              setDisableChecked(e.target.checked);
            }}
          />
        </Row>
      </Modal.Body>
    </Modal>
  );
};

export default PopupNoticeModal;
