import React, { useContext } from 'react';

import { Modal, Button, Form, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { NoticeList } from '../model/Workplace';

import { Formik } from 'formik';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './SystemNoticeModifyModal.scss';
import * as yup from 'yup';
// import WorkplaceService from '../../workplace/workplaceManagement/services/WorkplaceService';
import WorkplaceService from '../services/WorkplaceService';
import { AuthContext } from '../../../App';
import { ActionType } from '../../../reducer/actions';

export interface SystemNoticeModifyModalI {
  notice: NoticeList | null;
  onHide: (notice: NoticeList) => void;
}

export const SystemNoticeModifyModal: React.FC<SystemNoticeModifyModalI> = (props: SystemNoticeModifyModalI) => {
  const { t } = useTranslation();
  const { state, dispatch } = useContext(AuthContext);
  const workplaceService = new WorkplaceService();
  const MIN_DATE = '00000000';
  const MAX_DATE = '99999999';
  const dateToString = (target: Date | null, boundaryDate: string) => {
    if (target) {
      return (
        target
          .getFullYear()
          .toString()
          .padStart(4, '0') +
        (target.getMonth() + 1).toString().padStart(2, '0') +
        target
          .getDate()
          .toString()
          .padStart(2, '0')
      );
    } else {
      return boundaryDate;
    }
  };

  const stringToDate = (target: string | null) => {
    if (!target || target === MIN_DATE || target === MAX_DATE) {
      return null;
    } else {
      return new Date(+target.substr(0, 4), +target.substr(4, 2) - 1, +target.substr(6, 2));
    }
  };

  const callUploadService = async (uploadFile: File) => {
    return await workplaceService.uploadPolicyContents('notice', uploadFile);
  };
  const setLoading = (_isLoading: boolean) => {
    dispatch({ type: ActionType.LOADING, loading: { isLoading: _isLoading } });
  };

  const handleChangeFile = async (event: any) => {
    const result = {
      uploadResult: 'FAIL',
      contentsUrl: '',
      fileName: '',
      fileSize: 0,
      fileType: '',
    };
    if (event.target.files && event.target.files[0]) {
      const uploadFile: File = event.target.files[0];

      const response = await callUploadService(uploadFile);
      if (response && response.contentsUrl) {
        result.uploadResult = 'SUCCESS';
        result.contentsUrl = response.contentsUrl;
        result.fileName = uploadFile.name;
        result.fileSize = uploadFile.size;
        result.fileType = uploadFile.type;
      }
      //}
    }
    return result;
  };
  const schema = yup.object({
    noticeTitle: yup
      .string()
      .required()
      .max(1500),
    noticeContent: yup
      .string()
      .required()
      .max(4000),
  });

  const path = process.env.REACT_APP_S3_FILE_DOWNLOAD_URL || '';

  const filesizeFormatter = (bytes: number): string => {
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    if (bytes === 0) return '0 Byte';
    const i = Math.floor(Math.log(bytes) / Math.log(1024));
    return '(' + Math.round((bytes / Math.pow(1024, i)) * 100) / 100 + sizes[i] + ')';
  };

  const initialValues: NoticeList = {
    noticeId: '',
    noticeTitle: '',
    noticeContent: '',
    registerDate: '',
    noticeStartDt: '00000000',
    noticeEndDt: '99999999',
    importanceYn: 'N',
    popupYn: 'N',
    useYn: 'Y',
    fileId: '',
    fileName: '',
    fileOriginalName: '',
    filePath: '',
    fileExtension: '',
    fileSize: -1,
  };
  return (
    <Modal
      show={true}
      onHide={props.onHide}
      centered={true}
      className="notice-modify-modal"
      data-testid="notice-modify-modal"
      id="noticeModifyModal"
    >
      <Modal.Header closeButton />

      <Modal.Body>
        <Formik
          validateOnMount
          initialValues={props.notice ? props.notice : initialValues}
          onSubmit={values => {
            console.log(values);
          }}
          validationSchema={schema}
        >
          {({
            values,
            errors,
            isSubmitting,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldError,
            setFieldValue,
            isValid,
          }): any => (
            <Form>
              <Form.Row>
                <Col sm={2}>제목</Col>
                <Col>
                  <Form.Control
                    id="noticeTitle"
                    name="noticeTitle"
                    data-testid="noticeTitle"
                    required
                    type="text"
                    placeholder={'제목을 입력하세요.'}
                    value={values.noticeTitle}
                    onChange={e => {
                      setFieldValue('noticeTitle', e.target.value);
                    }}
                    onBlur={handleBlur}
                    isInvalid={!!errors.noticeTitle}
                  />
                </Col>
              </Form.Row>
              <Form.Row>
                <Col sm={2}>게시기간</Col>
                <Col>
                  <div className="start-date">
                    <DatePicker
                      placeholderText="제한없음"
                      dateFormat="yyyy/MM/dd"
                      selected={stringToDate(values.noticeStartDt)}
                      onChange={date => {
                        const startDateString = dateToString(date, MIN_DATE);

                        if (values.noticeEndDt < startDateString) {
                          setFieldValue('noticeEndDt', startDateString);
                        }
                        setFieldValue('noticeStartDt', startDateString);
                      }}
                      isClearable
                    />
                  </div>
                  <div className="date-splitter">{' ~ '}</div>
                  <div className="end-date">
                    <DatePicker
                      placeholderText="제한없음"
                      dateFormat="yyyy/MM/dd"
                      selected={stringToDate(values.noticeEndDt)}
                      minDate={stringToDate(values.noticeStartDt)}
                      onChange={date => {
                        const endDateString = dateToString(date, MAX_DATE);
                        if (values.noticeStartDt > endDateString) {
                          setFieldValue('noticeStartDt', endDateString);
                        }
                        setFieldValue('noticeEndDt', endDateString);
                      }}
                      isClearable
                    />
                  </div>
                </Col>
              </Form.Row>
              <Form.Row>
                <Col sm={2}>첨부파일</Col>
                <Col>
                  <Form.Control
                    id="fileOriginalName"
                    name="fileOriginalName"
                    data-testid="fileOriginalName"
                    type="file"
                    title={values.fileOriginalName ? values.fileOriginalName + '.' + values.fileExtension : ''}
                    accept=".pdf"
                    onChange={async e => {
                      setLoading(true);
                      const uploadResult = await handleChangeFile(e);

                      if (uploadResult.uploadResult === 'SUCCESS') {
                        const fileFullName = uploadResult.fileName.split('.');
                        setFieldValue('fileId', '');
                        setFieldValue('fileName', fileFullName[0]);
                        setFieldValue('fileExtension', fileFullName[1]);
                        setFieldValue('fileSize', uploadResult.fileSize);
                        setFieldValue('fileOriginalName', uploadResult.contentsUrl.split('/notice/')[1]);
                      }
                      setLoading(false);
                    }}
                    onBlur={handleBlur}
                    isInvalid={!!errors.fileOriginalName}
                  />
                  {values.fileName && values.fileExtension && values.fileSize && values.fileOriginalName && (
                    <a
                      href={path + values.filePath + '/' + values.fileOriginalName}
                      target="_blank"
                      download={values.fileName}
                    >
                      {values.fileName + '.' + values.fileExtension + ' ' + filesizeFormatter(values.fileSize)}
                    </a>
                  )}
                </Col>
              </Form.Row>
              <Form.Row>
                <Col sm={2}>내용</Col>
                <Col>
                  <Form.Control
                    id="noticeContent"
                    name="noticeContent"
                    data-testid="noticeContent"
                    required
                    as="textarea"
                    rows={10}
                    placeholder={'내용을 입력하세요.'}
                    value={values.noticeContent}
                    onChange={e => {
                      setFieldValue('noticeContent', e.target.value);
                    }}
                    onBlur={handleBlur}
                    isInvalid={!!errors.noticeContent}
                  />
                </Col>
              </Form.Row>
              <Form.Row>
                <Col sm={2}>중요여부</Col>
                <Col>
                  <Form.Check
                    id="importanceYn"
                    name="importanceYn"
                    data-testid="importanceYn"
                    type="checkbox"
                    checked={values.useYn === 'Y' && values.importanceYn === 'Y'}
                    onChange={e => {
                      setFieldValue('importanceYn', e.target.checked ? 'Y' : 'N');
                    }}
                    onBlur={handleBlur}
                    isInvalid={!!errors.importanceYn}
                    disabled={values.useYn !== 'Y'}
                  />
                </Col>
              </Form.Row>
              <Form.Row>
                <Col sm={2}>팝업여부</Col>
                <Col>
                  <Form.Check
                    id="popupYn"
                    name="popupYn"
                    data-testid="popupYn"
                    type="checkbox"
                    checked={values.useYn === 'Y' && values.popupYn === 'Y'}
                    onChange={e => {
                      setFieldValue('popupYn', e.target.checked ? 'Y' : 'N');
                    }}
                    onBlur={handleBlur}
                    isInvalid={!!errors.popupYn}
                    disabled={values.useYn !== 'Y'}
                  />
                </Col>
              </Form.Row>
              <Form.Row>
                <Col sm={2}>사용여부</Col>
                <Col>
                  <Form.Check
                    id="useYn"
                    name="useYn"
                    data-testid="useYn"
                    type="checkbox"
                    checked={values.useYn === 'Y'}
                    onChange={e => {
                      setFieldValue('useYn', e.target.checked ? 'Y' : 'N');
                    }}
                    onBlur={handleBlur}
                    isInvalid={!!errors.useYn}
                  />
                </Col>
              </Form.Row>
              <Form.Row>
                <Button
                  className="btn-ok"
                  onClick={() => {
                    props.onHide({ ...values, registerDate: dateToString(new Date(), MAX_DATE) });
                  }}
                  disabled={isSubmitting || !isValid}
                >
                  {t('reservation.label.SystemNoticeModal.confirm')}
                </Button>
              </Form.Row>
            </Form>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  );
};

export default SystemNoticeModifyModal;
