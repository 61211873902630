import React from 'react';

import './TermAgreeChangeModal.scss';
import { Modal, Container, Button } from 'react-bootstrap';

import { useTranslation } from 'react-i18next';
import DOMPurify from 'dompurify';

export interface TermAgreeChangeModalProps {
  changedAgreeYN: boolean;
  onHide: (checkedYN: boolean | undefined) => void;
}

export const TermAgreeChangeModal: React.FC<TermAgreeChangeModalProps> = (props: TermAgreeChangeModalProps) => {
  const { t } = useTranslation();
  return (
    <Modal show={true} centered={true} data-testid="agreeChangeModal" id="agreeChangeModal">
      <Modal.Body>
        <Container className="terms-agree-change-modal">
          <div className="term-agree-change-content">
            <label className="term-change-comment">
              {props.changedAgreeYN ? (
                <p
                  id="willYouAgreeComment"
                  data-testid="will-you-agree-comment"
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(
                      t('member.label.TermAgreeChangeModal.willYouAgree', {
                        interpolation: { escapeValue: false },
                      })
                    ),
                  }}
                />
              ) : (
                <p
                  id="willYouDisagreeComment"
                  data-testid="will-you-disagree-comment"
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(
                      t('member.label.TermAgreeChangeModal.willYouDisagree', {
                        interpolation: { escapeValue: false },
                      })
                    ),
                  }}
                />
              )}
            </label>
            <Button
              variant="secondary"
              id="cancelButton"
              name="cancelButton"
              data-testid="cancelButton"
              className="cancel-button"
              onClick={() => {
                props.onHide(undefined);
              }}
            >
              {t('member.label.TermAgreeChangeModal.cancel')}
            </Button>
            <Button
              variant="secondary"
              id="okButton"
              name="okButton"
              data-testid="okButton"
              className="ok-button"
              onClick={() => {
                props.onHide(props.changedAgreeYN);
              }}
            >
              <i id="firstRightArrow" data-testid="firstRightArrow" className="material-icons check-icon">
                check
              </i>
              {t('member.label.TermAgreeChangeModal.ok')}
            </Button>
          </div>
        </Container>
      </Modal.Body>
    </Modal>
  );
};

export default TermAgreeChangeModal;
