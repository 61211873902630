import React, { useState, useEffect, useContext } from 'react';
import './PrivacyPolicyPage.scss';
import { Modal, Container, Dropdown, OverlayTrigger, Tooltip, Image } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import DOMPurify from 'dompurify';
import TermService from '../../pages/member/services/TermService';
import PrivacyPolicyVersion from '../../pages/member/model/PrivacyPolicyVersion';
import PrivayPolicyVersionResponse from '../../pages/member/model/PrivacyPolicyVersionResponse';
import generalInfoCollection from '../../icons/general_info_collection.png';
import procedureCommission from '../../icons/procedure_commission.png';
import thridPartyOffer from '../../icons/3rd_party_offer.png';
import greivanceProcedure from '../../icons/grievance_procedure.png';
import { AuthContext } from '../../App';
import { ActionType } from '../../reducer/actions';

export interface PrivacyPolicyModal {
  onHide: () => void;
}
const versionMap = new Map<number, string>();
export const PrivacyPolicyPage: React.FC<PrivacyPolicyModal> = (props: PrivacyPolicyModal) => {
  const termService = new TermService();
  const { t } = useTranslation();
  const { dispatch } = useContext(AuthContext);
  const [currentVersion, setCurrnetVersion] = useState<string>();
  const [currentVersionId, setCurrnetVersionId] = useState<number>();
  const [privacyPolicyVersionItem, setPrivacyPolicyVersionItem] = useState<string>();
  const [privacyPolicyDetail, setPrivacyPolicyDetail] = useState<string>('');
  const handleSelect = (eventKey: any, event: Record<string, any>) => {
    setCurrnetVersion(versionMap.get(+eventKey));
    setCurrnetVersionId(eventKey);
  };

  const setLoading = (_isLoading: boolean) => {
    dispatch({ type: ActionType.LOADING, loading: { isLoading: _isLoading } });
  };
  const hideModal = () => {
    window.location.assign('/home');
  };
  /* eslint-disable */
  useEffect(() => {
    if (currentVersionId) {
      const fetchTermData = async () => {
        return await termService.requestPrivacyPolicy(currentVersionId);
      };
      setLoading(true);
      fetchTermData().then(res => {
        if (res.successOrNot === 'Y') {
          setPrivacyPolicyDetail(res.data[0].termsDetailContents);
        } else {
          setPrivacyPolicyDetail('');
        }
      }).finally(() => {
        setLoading(false);
      });
    }
  }, [currentVersionId]);

  useEffect(() => {
    let dropdownArea = [] as any;
    const fetchTermData = async () => {
      return await termService.requestPrivacyPolicyVersion();
    };
    setLoading(true);
    fetchTermData().then(res => {
      if (res.successOrNot === 'Y' && res.data) {
        const privacyPolicyVersionInfo: PrivacyPolicyVersion[] = res.data as PrivacyPolicyVersion[];
        for (let i = 0; i < privacyPolicyVersionInfo?.length; i++) {
          const dropdownItem = (
            <Dropdown.Item key={i} eventKey={privacyPolicyVersionInfo[i].termsId + ''}>
              {privacyPolicyVersionInfo[i].termsVersion}
            </Dropdown.Item>
          );
          dropdownArea.push(dropdownItem);
          versionMap.set(privacyPolicyVersionInfo[i].termsId, privacyPolicyVersionInfo[i].termsVersion);
        }
        setCurrnetVersion(privacyPolicyVersionInfo[0].termsVersion);
        setPrivacyPolicyVersionItem(dropdownArea);
        setCurrnetVersionId(privacyPolicyVersionInfo[0].termsId);
      } else {
        setPrivacyPolicyDetail('');
      }
    }).finally(() => {
      setLoading(false);
    });
  }, []);
  /* eslint-enable */

  return (
    <Modal
      show={true}
      onHide={hideModal}
      centered={true}
      className="privacy-policy-modal"
      data-testid="privacy-policy-modal-test"
      id="PrivacyPolicyModal"
    >
      <Modal.Header closeButton>
        <div className="title bold">{t('member.label.PrivacyPolicyModal.header.title')}</div>
      </Modal.Header>
      <Modal.Body>
        <Container>
          <div className="modal__subheader">
            <div className="modal__subheader--dropdown">
              <label>{t('member.label.PrivacyPolicyModal.header.versionLabel')}</label>
              <Dropdown onSelect={handleSelect}>
                <Dropdown.Toggle id="dropdown">{currentVersion}</Dropdown.Toggle>
                <Dropdown.Menu>{privacyPolicyVersionItem}</Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
          <div className="divTerms">
            <div className="labeling-header">{t('member.label.PrivacyPolicyModal.header.titleSub')}</div>
            <div className="labeling-contents">
              <div className="labeling-content">
                <OverlayTrigger overlay={<Tooltip>임직원정보, 회원정보(외부인), 방문신청정보</Tooltip>}>
                  <Image src={generalInfoCollection} id="generalInfoCollection" className="privacy-pics" />
                </OverlayTrigger>
                <span>일반 개인정보 수집</span>
              </div>
              <div className="labeling-content">
                <OverlayTrigger overlay={<Tooltip>AWS(Amazon Web Service)</Tooltip>}>
                  <Image src={procedureCommission} id="procedureCommission" className="privacy-icons" />
                </OverlayTrigger>
                <span>처리위탁</span>
              </div>
              <div className="labeling-content">
                <OverlayTrigger
                  overlay={
                    <Tooltip>
                      (주)LG, LG전자, LG디스플레이, LG이노텍, LG유플러스, D&O Corp., LG경영 연구원, LG AI Research,
                      LG마그나
                    </Tooltip>
                  }
                >
                  <Image src={thridPartyOffer} id="thridPartyOffer" className="privacy-icons" />
                </OverlayTrigger>
                <span>제 3자 제공</span>
              </div>
              <div className="labeling-content">
                <OverlayTrigger
                  overlay={<Tooltip>부서명 : 융합보안팀 연락처 : 1644-5590, visitportal@lgcns.com</Tooltip>}
                >
                  <Image src={greivanceProcedure} id="greivanceProcedure" className="privacy-pics" />
                </OverlayTrigger>
                <span>고충처리부서</span>
              </div>
            </div>
            ※ 기호에 마우스 커서를 대시면 세부 사항을 확인할 수 있으며, 자세한 내용은 아래의 개인정보처리방침을
            확인하시기 바랍니다.
            <div
              className="terms-content"
              data-testid="terms-acception-detail"
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(privacyPolicyDetail),
              }}
            />
          </div>
        </Container>
      </Modal.Body>
    </Modal>
  );
};

export default PrivacyPolicyPage;
